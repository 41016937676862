import sidebarData from "./sidebarData";
import localkings from "../../assets/images/Group 276.png";
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import SidebarNav from "./SidebarNav";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Dashboard } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import logoutIcon from "../../assets/images/logout.svg";

const useStyles = makeStyles((theme) => ({
  nested: {
    // paddingLeft: theme.spacing(4),
  },
  navigate: {
    textDecoration: "none",
    color: "black",
  },

  titleLogo: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2rem 3.5rem",
    cursor: "pointer",
  },
}));

const SidebarDrawer = ({
  open,
  setOpen,
  direction,
  sideBarData,
  setSideBarData,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogoutBtn = () => {
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to <span className="font-weight-700">Logout</span> ?</p>`;
    swal({
      content: form,
      className: "custom_delete_sweet_alert",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(function (isConfirm) {
      if (isConfirm === true) {
        localStorage.removeItem("persist:root");
        window.location.reload();
      }
    });
  };

  const navigate = useNavigate();

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      anchor={direction}
      open={open}
      onClose={handleDrawerToggle}
    >
      <div className="sidebar">
        <div
          className={classes.titleLogo}
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={localkings} alt="icon" />
        </div>

        {sidebarData.map((item) => {
          return <SidebarNav setSideBarData={setSideBarData}  sideBarData={sideBarData} key={item.id} item={item} />;
        })}
        <ListItem
          style={{ paddingLeft: "32px !important" }}
          button
          className={classes.nested}
          onClick={handleLogoutBtn}
        >
          <ListItemIcon>
            <img src={logoutIcon} color="black" />
          </ListItemIcon>
          <ListItemText className="logout-text" primary="Logout" />
        </ListItem>
      </div>
    </Drawer>
  );
};

export default SidebarDrawer;
