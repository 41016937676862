import "./styles.modules.css"
import { Link, NavLink, useLocation } from "react-router-dom";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    navigate: {
        textDecoration: "none",
        color: "black",
    },
}));

export default function SidebarList({ item, handleClose }) {
    const location = useLocation();
    const classes = useStyles();

    return (
        <>
            <NavLink className={` ${classes.navigate}`} to={item.path}>
                <ListItem button className={classes.nested} onClick={handleClose}>
                    <ListItemIcon className="inactiveicon">{item.icon}</ListItemIcon>
                    <ListItemText className="listItem-title" primary={item.title} />
                </ListItem>
            </NavLink>
        </>
    );
}
