import React from "react";
import AddWalkInScreenForm from "../components/forms/AddWalkInScreenForm";
import Layout from "../layout";

const AddWalkInScreen = () => {
    return (
        <Layout>
            <AddWalkInScreenForm />
        </Layout>
    );
};

export default AddWalkInScreen;
