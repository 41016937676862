import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteUser, deleteCoupon, deleteWalkInScreens } from "../../redux/user/action";
import "./Menu.css";

const useStyle = makeStyles({
    menuItem: {
        fontSize: 14,
        padding: "6px 60px 5px 24px !important",
        color: "#4A4A4A",
    },
});

export default function VertMenu({ data, type, currentPage }) {
    const location = useLocation();
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [userData, setUserData] = useState();
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleEdit = (data, currentPage) => {
        data.currentPage = currentPage;
        const path = type === "couponList" ? "/coupon-list/edit-Coupon" : type === "localkingList" ? "/users/list-localkings/edit-localKing" : type === "walkinList" ? "/list-of-walk-in-screen/edit-walk-in-screen/" : "/users/List-of-Customers/edit-customer";
        navigate(path, { state: data, });
    };

    const handleDelete = (data, currentPage) => {
        dispatch(location.pathname.includes('coupon-list') ? deleteCoupon(data.id,currentPage, data.couponCode) : location.pathname.includes("list-of-walk-in-screens") ? deleteWalkInScreens(data.id, currentPage) : deleteUser(data.id, currentPage));
    }

    return (
        <>
            <MoreVert onClick={handleClick} />
            {/* <div style={{display:"none"}} ><EditCustomerForm name={"userData"} /></div> */}

            <Menu

                anchorEl={open}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >


                <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                        handleClose();
                        handleEdit(data, currentPage);
                    }}>
                    Edit
                </MenuItem>

                <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                        handleClose();
                        handleDelete(data, currentPage);
                    }}>
                    Delete
                </MenuItem>

            </Menu>
        </>
    );
}
