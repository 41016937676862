import React, { useEffect, useState } from "react";
import InputField from "../custom/Input";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import CustomButton from "../custom/Button";
import NavBar from "../custom/navBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/auth/action";
import { getCountries, getCountryCallingCode } from 'react-phone-number-input'
import Imageselector from "../custom/imageSelector";


export default function AddCustomerForm({ NameOfuser, role }) {

    const useStyles = makeStyles({
        formBox: {},
        formGroup: {
            display: "flex",
            flexDirection: "column",
        },
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
            width: "100%",
        },
        imageSelector: {
            width: "100%",
            border: "1px solid #E6EAF0",
            borderRadius: "5px",
        },
        selector: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
            width: "100%",
        },
        phoneCode: {
            border: "1px solid #E6EAF0",
            borderRadius: "5px",
            background: "transparent",
            width: "75px",
            padding: "0px 10px",
            fontSize: "14px",
            color: "#8E8E8E",
            "&:focus-visible": {
                outline: "none"
            }
        },
        SuccessAlert: {
            position: "absolute",
            width: "100%",
            top: "-130px", // ON show
            top: "-260px",  // ON Hide
            left: "0",
            zIndex: 1024,
            backgroundColor: "#EAFFEA !important",
            borderRadius: "8px !important",
            height: "89px",
            alignItems: "center",
            padding: "0px 30px !important",
            transition: "all 7s ease !important",
            "& span": {
                color: "#1F7900",
                fontSize: "26px",
                fontWeight: 500,
                marginLeft: "35px",
            },
            "& div": {
                display: "flex",
                alignItems: "center",
                padding: "0px",
            },

        }


    });

    const classes = useStyles();
    const [getInputField, setInputField] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneCode: "+91",
        mobile: "",
        image: "",
    });


    const InputsObj = [
        {
            id: 1,
            label: "First Name",
            palceHolder: "First Name",
            type: "text",
            name: "firstName",
            requied: "requied",
            value: getInputField?.firstName,
        },
        {
            id: 2,
            label: "Last Name",
            palceHolder: "Last Name",
            type: "text",
            name: "lastName",
            requied: "requied",
            value: getInputField?.lastName,
        },
        {
            id: 3,
            label: "Email",
            palceHolder: "Email Address",
            type: "email",
            name: "email",
            requied: "requied",
            value: getInputField?.email,
        },

        {
            id: 4,
            label: "Mobile Number",
            palceHolder: "Mobile Number",
            type: "number",
            name: "mobile",
            requied: "requied",
            value: getInputField?.mobile,
        },
        {
            id: 5,
            imgLabel: "Photo",
            palceHolder: "Select Image",
            type: "file",
            name: "image",
            requied: "requied",
            value: getInputField?.image,
        },
    ];

    const register = useSelector(({ registerReducer }) => registerReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target?.files?.length > 0) {
            var file = e.target.files["0"];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputField({ ...getInputField, [name]: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            setInputField({ ...getInputField, [name]: value });
        }
    };


    const handleclick = async (e) => {
        e.preventDefault();
        const responseData = await dispatch(registerUser(getInputField));
        if (responseData.status === 200) {
            navigate('/users/List-of-Customers')
        }
    };



    const location = useLocation();
    return (
        <>
            {register.loading && (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "123vh",
                        width: "70%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <CircularProgress />
                </Grid>
            )}

            <NavBar title="Users" url={location.pathname} />

            <div className="formBox">

                <form onSubmit={handleclick}>

                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <h2 className="mb-4 font-29 font-weight-700" style={{ color: "#1F4173" }}> Add customer </h2>
                        </div>
                        <div className="col-md-6 mb-5">
                            <Link to="/users/List-of-Customers" className="mt-3 d-block font-19 font-weight-700 text-decoration-none text-end text-sapphire-glitter-color">Back</Link>
                        </div>
                    </div>

                    <div className="row mb-5 mt-4">
                        <div className="co-md-12 text-center">
                            <Imageselector imgUrl={getInputField.image} handleChangeImage={handleChange} />
                        </div>
                    </div>

                    <div className="row">
                        {InputsObj.map((val) => {
                            const cl = `col-lg-${val.type === "file" ? 12 : 6} col-sm-12`;
                            return (
                                <div className={cl} key={val.id}>
                                    {val.name === "mobile" ? <label className="form-label d-block" for="input-field">Mobile Number</label> : null}
                                    <div className={`mb-4 ${val.name === "mobile" ? "d-flex" : null}`}>
                                        {val.name === "mobile" ? <select className={`me-2 ${classes.phoneCode}`} name="phoneCode" onChange={handleChange} value={getInputField.phoneCode}>
                                            {getCountries().map((country) => (
                                                <>
                                                    <option key={country} value={`+${getCountryCallingCode(country)}`}>
                                                        + {getCountryCallingCode(country)}
                                                    </option>
                                                </>
                                            ))}
                                        </select> : null}
                                        <InputField
                                            id={val.id}
                                            label={val.name !== "mobile" ? val.label : null}
                                            type={val.type}
                                            placeholder={val.palceHolder}
                                            option={val.option}
                                            handleChange={handleChange}
                                            name={val.name}
                                            imageUrl={getInputField.image}
                                            classStyle={classes}
                                            value={val.value}
                                            requied={val.requied}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                        }}
                        className="col-lg-12 col-sm-12 "
                    >
                        <label className="form-label" htmlFor="input-field">
                            {InputsObj[4].imgLabel}
                        </label>
                        <label className={`${classes.imageSelector} pb-5 pt-5`}>
                            <div className="text-center">
                                {getInputField.image ? (
                                    <img
                                        style={{
                                            boxShadow: " 0px 4px 4px rgb(0 0 0 / 25%)",
                                            borderRadius: "6px",
                                            height: "400px",
                                            width: "auto",
                                        }}
                                        src={getInputField.image}
                                        alt="vector"
                                    />
                                ) : (
                                    <img
                                        style={{
                                            boxShadow: " 0px 4px 4px rgb(0 0 0 / 25%)",
                                            borderRadius: "6px",
                                        }}
                                        src={"/Vector.png"}
                                        alt="vector"
                                    />
                                )}
                                <p
                                    style={{
                                        textAlign: "center",
                                        cursor: "pointer",
                                        color: "#8E8E8E",
                                    }}
                                    className="mt-4 mb-0"
                                >
                                    {getInputField.image ? null : (
                                        <p className="mb-0">Drag and Drop a file here or Click</p>
                                    )}
                                </p>
                            </div>
                            <input hidden onChange={handleChange} name="image" type="file" />
                        </label>
                    </div> */}

                    <div className="row mb-5">
                        <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                            <div className="row">
                                <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                    <CustomButton
                                        className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                        type="submit"
                                        style={{
                                            background: "#003AD2",
                                            borderRadius: "8px",
                                            width: "290px",
                                            height: "55px",
                                            fontSize: "26px"
                                        }}>
                                        Add
                                    </CustomButton>
                                </div>

                                <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                    <CustomButton
                                        style={{
                                            color: "#1F4173",
                                            width: "140px !important ",
                                            textTransform: "capitalize",
                                            height: "55px",
                                            fontWeight: 700,
                                            fontSize: "26px"
                                        }}
                                        onClick={() => navigate("/users/List-of-Customers")}>
                                        Cancel
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}