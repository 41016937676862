import EditCouponForm from "../components/forms/editCoupon";
import Layout from "../layout";

export default function EditCoupon() {
  return (
    <Layout> 
          <EditCouponForm/>
    </Layout>
  );
}
