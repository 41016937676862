const authActionTypes = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    REDIRECT: "REDIRECT",

    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILURE: "REGISTER_FAILURE",

    GET_ME_REQUEST: "GET_ME_REQUEST",
    GET_ME_SUCCESS: "GET_ME_SUCCESS",
    GET_ME_FAILURE: "GET_ME_FAILURE",

    UPDATE_REQUEST: "UPDATE_REQUEST",
    UPDATE_SUCCESS: "UPDATE_SUCCESS",
    UPDATE_FAILURE: "UPDATE_FAILURE",

    EMAIL_UPDATE_REQUEST: "EMAIL_UPDATE_REQUEST",
    EMAIL_UPDATE_SUCCESS: "EMAIL_UPDATE_SUCCESS",
    EMAIL_UPDATE_FAILURE: "EMAIL_UPDATE_FAILURE",

    MOBILE_UPDATE_REQUEST: "MOBILE_UPDATE_REQUEST",
    MOBILE_UPDATE_SUCCESS: "MOBILE_UPDATE_SUCCESS",
    MOBILE_UPDATE_FAILURE: "MOBILE_UPDATE_FAILURE",
    
    // COUPON_CREATED_REQUEST: "COUPON_CREATED_REQUEST",
    COUPON_CREATED_SUCCESS: "COUPON_CREATED_SUCCESS",
    COUPON_CREATED_FAILURE: "COUPON_CREATED_FAILURE",

    SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
    SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
    SEND_OTP_FAILURE: "SEND_OTP_FAILURE",

    VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",

    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

    


};

export default authActionTypes;
