import AddProfileForm from "../components/forms/addProfile";
import Layout from "../layout";

export default function AddProfile() {
    return (
        <Layout>
            <AddProfileForm />
        </Layout>
    );
}
