const sweetAlert = {
  REGISTER_SUCCESS: "User Created Successfully.",
  REGISTER_FAILURE: "Some error occured while adding user",
  
  LOGIN_SUCCESS: "Login Successfully",
  LOGIN_FAILURE: "Trouble logging, Please try again!!",

  EMAIL_UPDATE_SUCCESS: "Email updated successfully",
  EMAIL_UPDATE_FAILURE: "Trouble updating email, Please try again!!",
  MOBILE_UPDATE_SUCCESS: "Mobile number updated successfully",
  MOBILE_UPDATE_FAILURE: "Trouble updating mobile number, Please try again!!",
  DELETE_USER_SUCCESS: "User deleted successfully",
  DELETE_USER_FAILURE: "Trouble deleting user, Please try again!!",
  ORDER_CANCEL_SUCCESS: "Order cancelled successfully",
  ORDER_CANCEL_FAILURE: "Trouble cancelling order, Please try again!!",
  PAYOUT_SUCCESS: "Localking Paid successfully",
  PAYOUT_FAILURE: "Trouble paying localking, Please try again!!",

  SCHEDULE_SUCCESS: "Schedule payout created  successfully",
  SCHEDULE_FAILURE: "Trouble creating schedule payout, Please try again!!",


  COUPON_CREATED_SUCCESS: "Coupon Created Successfully",
  COUPON_CREATED_FAILURE: "Trouble creating coupon, Please try again!!",


  DELETE_COUPON_SUCCESS: "Coupon Delete Successfully",
  DELETE_COUPON_FAILURE: "something went wrong deleting",


  ACTIVE_USER_SUCCESS: "Successfully Updated!",
  ACTIVE_USER_FAILURE: "Something went Wrong!",


  CREATE_WALK_IN_SCREEN_SUCCESS: "Create Walk-in Screen Successfully!",
  CREATE_WALK_IN_SCREEN_FAILURE: "Something went Wrong!",

 
  DELETE_WALK_IN_SCREEN_SUCCESS_MSG: "Walk-in Screen Delete Successfully!",
  DELETE_WALK_IN_SCREEN_FAILURE_MSG: "Something went Wrong!",

  UPDATE_WALK_IN_SCREEN_SUCCESS_MSG: "Walk-in Screen Updated Successfully!",
  UPDATE_WALK_IN_SCREEN_FAILURE_MSG: "Something went Wrong!",

};

export default sweetAlert;
