import React from "react";
import { makeStyles } from "@material-ui/core";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import protectedRoutes from "../../routes/RoutePath";
import transitions from "@material-ui/core/styles/transitions";
import { useLocation } from "react-router-dom";
import { fontSize } from "@mui/system";

export default function NavBar({ title, url }) {
    const useStyles = makeStyles({
        title: {
            color: "rgb(0,58,210)",
            marginBottom: "5px",
            fontWeight: 700,
            fontSize: "38px"
        },
        url: {
            color: "#8FA0B9",
            marginTop: "0px",
            fontSize: "18px"
        },
    });
    const urls = url.split("/").filter((item) => item !== "");
    const urlArr = []

    for (let x of urls) {
        let value = x.split("-").join(" ").toLowerCase().split(" ")
        for (var i = 0; i < value.length; i++) {
            if (value.length === 3 && value[i] === "customer") {
                value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1) + "s";
            } if (value[i] === "of") {
                continue;
            }if (value.length === 2 && value[i] === "list") {
                continue;
            } else {
                value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
            }
        }
        urlArr.push(value.join(" "));
    }

    const classes = useStyles();

    function convertToObj(urls, urlArr) {
        if (urls.length != urlArr.length || urls.length == 0 || urlArr.length == 0) {
            return null;
        }

        // Using reduce() method
        let object = urls.reduce((acc, element, index) => {
            return {
                ...acc,
                [element]: urlArr[index],
            };
        }, {});

        return object;
    }
    const breadcrumbsObj = convertToObj(urlArr, urls);

    return (
        <>
            <div>
                <h1 className={classes.title}>{title}</h1>
                <Breadcrumbs separator=">>" aria-label="breadcrumb" style={{ marginBottom: "20px" }}>
                    {Object.keys(breadcrumbsObj).map((item, index) => {
                        return Object.keys(breadcrumbsObj).indexOf(Object.keys(breadcrumbsObj)[index]) === Object.keys(breadcrumbsObj).length - 1 ? (
                            <Typography key={index} className={
                                (Object.keys(breadcrumbsObj).length) == 2 ? 'text-sapphire-glitter-color font-22' : (Object.keys(breadcrumbsObj).length) == 3 ? 'text-sapphire-glitter-color font-22' : `${classes.url} font-22`
                            }
                            >
                                {item == 'coupon-list' ? 'Coupon list' : item}
                            </Typography>
                        ) : breadcrumbsObj[item] == "transactions" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/transactions`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "users" ? (
                            <Link underline="hover" className=" font-22 " color="inherit" href={`/users/List-of-Customers`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "List-of-Customer" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/users/List-of-Customers`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "List-of-Customers" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/users/List-of-Customers`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "list-of-localkings" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/users/list-localkings`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "list-of-walk-in-screen" ? (
                            <Link underline="hover" className="font-18" color="inherit" href={`/list-of-walk-in-screens`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "Settings" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "settings" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "profile" ? (
                            <Link underline="hover" className="font-22" color="inherit" href={`/Settings/profile`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "coupon-list" ? (
                            <Link underline="hover" className="font-18" color="inherit" href={`/coupon-list`}>
                                {item}
                            </Link>
                        ) : breadcrumbsObj[item] === "payout-list" ? (
                            <Link underline="hover" className="font-18" color="inherit" href={`/payout-list`}>
                                {item}
                            </Link>
                        ) : (
                            <Link underline="hover" className="font-22" color="inherit" href={`/users/${breadcrumbsObj[item]}`}>
                                {item}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
            </div>
        </>
    );
}
