
import React, { useState } from "react";
import CustomButton from "../custom/Button";
import NavBar from "../custom/navBar";
import InputField from "../custom/Input";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentmethodSetting } from "../../redux/user/action";
import swal from "sweetalert";

const PaymentSettingForm = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const useStyles = makeStyles({
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
            width: "100%",
        },

    });
    const classes = useStyles();

    const [getStripeInputField, setStripeInputField] = useState({
        publicKey: "",
        privateKey: "",
        mode: "",
        isDefault: false,
        type: "stripe",
        secretKey: ""
    });

    const StripeInputsObj = [

        {
            id: 1,
            label: "Public Key",
            palceHolder: "Enter public Key",
            type: "text",
            name: "publicKey",
            requied: "requied",
        },

        {
            id: 2,
            label: "Private Key",
            palceHolder: "Enter private Key",
            type: "text",
            name: "privateKey",
            requied: "requied",
        },

        {
            id: 3,
            label: "Secret Key",
            palceHolder: "Enter secret Key",
            type: "text",
            name: "secretKey",
            requied: "requied",
        },

        {
            id: 6,
            label: "Mode",
            type: "radio",
            requied: "requied",
            name: "mode",
            option: [
                { id: 2, value: 'sendbox', name: "Send Box", },
                { id: 3, value: 'live', name: "Live" },
            ],
        },

        {
            id: 7,
            label: "Set as Default",
            type: "checkbox",
            requied: "requied",
            name: "isDefault",
            option: [
                { id: 1, name: "Set Stripe as default." },
            ],
        },


    ];

    const register = useSelector(({ registerReducer }) => registerReducer);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "isDefault") {
            setStripeInputField({ ...getStripeInputField, isDefault: e.target.checked });
        } else {
            setStripeInputField({ ...getStripeInputField, [name]: value });
        }
    };


    const handleclick = (e) => {
        if (!getStripeInputField.publicKey || !getStripeInputField.privateKey || !getStripeInputField.mode || !getStripeInputField.type || !getStripeInputField.secretKey) {
            swal("Error!", "all field is required!", "error");
            return;
        }

        e.preventDefault();
        dispatch(paymentmethodSetting(getStripeInputField));
    };

    const location = useLocation();
    return (
        <>
            {register.loading && (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                </Grid>
            )}
            <NavBar title="Settings" url={location.pathname} />

            <div className="accordion-item border-0 border-top formBox mb-3 p-1 border-top-0">
                <button className="accordion-button bg-white pb-4 pt-4 collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseStripe" aria-expanded="true" aria-controls="collapseStripe">
                    <h2 className="font-29 mb-0 font-weight-700" style={{ color: "#1F4173" }}>Stripe</h2>
                </button>
                <div id="collapseStripe" className="accordion-collapse collapse" aria-labelledby="headingStripe" data-bs-parent="#accordionPaymentSetting">
                    <div className="accordion-body">

                        <div className="row mt-4">
                            {StripeInputsObj.map((val) => {
                                const cl = `col-lg-6 col-sm-12`;
                                return (
                                    <>
                                        <div className={cl} key={val.id}>
                                            <div className="mb-4">
                                                <InputField
                                                    id={val.id}
                                                    label={val.label}
                                                    type={val.type}
                                                    placeholder={val.palceHolder}
                                                    option={val.option}
                                                    handleChange={handleChange}
                                                    name={val.name}
                                                    requied={val.requied}
                                                    classStyle={classes}
                                                    className="custom-radio-btn"
                                                />
                                            </div>
                                        </div>

                                    </>
                                );
                            })}
                        </div>

                        <div className="row mt-5 pt-5 mb-5">
                            <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                                <div className="row">
                                    <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                        <CustomButton
                                            className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                            type="submit"
                                            style={{
                                                background: "#003AD2",
                                                borderRadius: "8px",
                                                width: "290px",
                                                height: "55px",
                                                fontSize: "26px"
                                            }}
                                            onClick={handleclick}
                                        >
                                            Save
                                        </CustomButton>
                                    </div>

                                    <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                        <CustomButton
                                            style={{
                                                color: "#1F4173",
                                                width: "140px !important ",
                                                textTransform: "capitalize",
                                                height: "55px",
                                                fontWeight: 700,
                                                fontSize: "26px"
                                            }}
                                            onClick={() => navigate("/")}>
                                            Cancel
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default PaymentSettingForm;
