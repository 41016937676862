import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtpSetinitialStateFalse, sendOtpToChangeEmailOrMobile, updateEmailSetinitialStateFalse } from "../../redux/auth/action";
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import NavBar from "../custom/navBar";
import CustomDefaultButton from "../custom/DefaultButton";
import OTPVerificationDialog from "../custom/OTPVerificationDialog";
import VerifiedOTPDialog from "../custom/VerifiedOTPDailog";

const useStyles = makeStyles(() => ({
    formGroup: {
        display: "flex",
        flexDirection: "column",
    },
    formControl: {
        border: (props) => {
            switch (props.bothEmail) {
                case "initail":
                    return "1px solid #E6EAF0";
                case "error":
                    return "2px solid red ";
                case "success":
                    return "2px solid green ";
                default:
                    return "1px solid #E6EAF0 ";
            }
        },
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
    },
    formControlDefault: {
        border: "1px solid #E6EAF0 ",
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
    },

    // label: {
    //     height: "100%",
    //     padding: "10px",
    //     maxWidth: "100%",
    //     marginTop: "1em",
    //     width: "calc(100% - 330px)",
    // },
    button: {
        background: "rgb(0, 58, 210)",
        width: "204px",
        border: "none",
        borderRadius: "5px",
        color: "white",
    },
}));

export default function ChangeemailAdd() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [bothEmail, setBothEmail] = useState();
    const [getInputField, setInputField] = useState({
        oldEmail: "",
        email: "",
        emailConfirm: "",
    });

    useEffect(() => {
        dispatch(sendOtpSetinitialStateFalse());
    }, [])

    useEffect(() => {
        dispatch(updateEmailSetinitialStateFalse());
    }, [])

    const forgotPasswordReducer = useSelector(state => state.forgotPasswordReducer);
    const emailReducer = useSelector(state => state.emailReducer);

    const crntEmail = JSON.parse(localStorage.getItem('persist:root'))
    const getemail = JSON.parse(crntEmail.loginReducer)
    const loginEmai = getemail.currentUser.data.currentUser.email
    const InputsObj = [
        {
            id: 1,
            label: "Current Email Address",
            palceHolder: "Enter current email",
            type: "email",
            name: "oldEmail",
            value: getInputField.oldEmail,
        },
        {
            id: 2,
            label: "New Email Address",
            type: "email",
            palceHolder: "Enter new email ",
            name: "email",
            value: getInputField.email,
        },
        {
            id: 3,
            label: "Confirm New Email Address",
            type: "email",
            palceHolder: "Re-enter new email ",
            name: "emailConfirm",
            value: getInputField.emailConfirm,
        },
    ];

    const val_1 = InputsObj[1]?.value;
    const val_2 = InputsObj[2]?.value;

    const myfun = () => {
        if (val_1 == "" && val_2 == "") {
            setBothEmail("initial");
        } else if (val_2 && val_1 !== val_2) {
            setBothEmail("error");
        } else if (
            val_1 !== "" &&
            val_1 !== "" &&
            val_2.startsWith(val_1) &&
            val_1.startsWith(val_2)
        ) {
            setBothEmail("success");
        }
    };

    useEffect(() => {
        myfun();
    }, [val_1, val_2]);


    const classes = useStyles({ bothEmail });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...getInputField, [name]: value });
    };

    const handleclick = (e) => {
        e.preventDefault();
        const data = {
            type: "email",
            oldEmail: getInputField?.oldEmail,
            email: getInputField?.email
        };
        loginEmai === getInputField.oldEmail ? dispatch(sendOtpToChangeEmailOrMobile(data)) : alert("Current Email Address does not exist")
    };

    if (forgotPasswordReducer?.isVerifiedEmail === true) {
        localStorage.setItem("email", getInputField?.email)
        localStorage.setItem("oldEmail", getInputField?.oldEmail)
        localStorage.setItem("type", "email")
    }

    setTimeout(() => {
        if (emailReducer.isChangeEmail === true) {
            localStorage.removeItem("persist:root");
            window.location.reload();
            window.localStorage.clear();
        }
    }, 3000);

    const location = useLocation();

    return (
        <>

            {emailReducer.loading && (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "70%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <CircularProgress />
                </Grid>
            )}

            {emailReducer.isChangeEmail === true ?
                <VerifiedOTPDialog title="OTP Verification" buttonTitle="Verify" className="OTPVerificationDialog" /> : null
            }

            {forgotPasswordReducer?.isVerifiedEmail === true ?
                <OTPVerificationDialog title="OTP Verification" buttonTitle="Verify" className="OTPVerificationDialog" errorMessage={emailReducer?.error?.response?.data?.message} openModelTrueFalse={forgotPasswordReducer?.isVerifiedEmail} /> : null
            }

            <NavBar title="Settings" url={location.pathname} />
            <div className="formBox">
                <form onSubmit={handleclick}>
                    <h2 className="mb-5 mt-2 font-29 font-weight-700" style={{ color: "#1F4173" }}> Change Email Address </h2>

                    <div className="col-lg-12 col-sm-12 mt-3">
                        {InputsObj.map((val) => {
                            const cl = `col-lg-${val.type === "file" ? 12 : 12} col-sm-12`;
                            return (
                                <div className={cl} key={val.id}>
                                    <div className="mb-4">
                                        <InputField
                                            id={val.id}
                                            label={val.label}
                                            type={val.type}
                                            value={val.value}
                                            placeholder={val.palceHolder}
                                            option={val.option}
                                            handleChange={handleChange}
                                            name={val.name}
                                            imageUrl={getInputField.photo}
                                            classStyle={classes}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {forgotPasswordReducer.error !== null ?
                        <div className="col-md-12">
                            <span className="font-15 font-weight-500 d-block" style={{ color: " #AF2828", }}>
                                {forgotPasswordReducer.error}
                            </span>
                        </div>
                        : null
                    }

                    <div className="row mt-5 pt-5 mb-5">
                        <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                            <div className="row">
                                <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                    <CustomDefaultButton
                                        className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                        type="submit"
                                        style={{
                                            background: "#003AD2",
                                            borderRadius: "8px",
                                            width: "290px",
                                            height: "55px",
                                            fontSize: "26px"
                                        }}
                                        bothEmail={bothEmail}
                                    >
                                        Save Changes
                                    </CustomDefaultButton>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                    <CustomButton
                                        style={{
                                            color: "#1F4173",
                                            width: "140px !important ",
                                            textTransform: "capitalize",
                                            height: "55px",
                                            fontWeight: 700,
                                            fontSize: "26px"
                                        }}
                                        onClick={() => navigate("/")}
                                    >
                                        Cancel
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    );
}
