
import React, { useEffect, useState } from "react";
import CustomButton from "../custom/Button";
import NavBar from "../custom/navBar";
import InputField from "../custom/Input";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getServicesAmount, updateServiceAmount } from "../../redux/user/action";
import swal from "sweetalert";

const useStyles = makeStyles({
    formControl: {
        border: "1px solid #E6EAF0 !important",
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
        width: "100%",
    },

});

const ServiceAmountUpdateForm = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getServicesAmounts = useSelector(
        ({ getServicesAmountReducer }) => getServicesAmountReducer.items.data
    );
    useEffect(() => {
        dispatch(getServicesAmount())
    }, [])


    const classes = useStyles();

    const [getReturnInputField, setReturnInputField] = useState({});
    const [getDeliveryInputField, setDeliveryInputField] = useState({});
    useEffect(() => {
        setReturnInputField({
            fetchAmount: getServicesAmounts?.Return[0]?.fetchAmount ? getServicesAmounts?.Return[0]?.fetchAmount : '',
            bringAmount: getServicesAmounts?.Return[0]?.bringAmount ? getServicesAmounts?.Return[0]?.bringAmount : '',
            currency: getServicesAmounts?.Return[0]?.currency ? getServicesAmounts?.Return[0]?.currency : 'eur',
        });
        setDeliveryInputField({
            fetchAmount: getServicesAmounts?.Delivery[0]?.fetchAmount ? getServicesAmounts?.Delivery[0]?.fetchAmount : '',
            bringAmount: getServicesAmounts?.Delivery[0]?.bringAmount ? getServicesAmounts?.Delivery[0]?.bringAmount : '',
            currency: getServicesAmounts?.Delivery[0]?.currency ? getServicesAmounts?.Delivery[0]?.currency : 'eur',
        });
    }, [getServicesAmounts])

    const ReturnServiceInputsObj = [

        {
            id: 1,
            label: "Fetch Price",
            palceHolder: "Enter Fetch Price",
            type: "number",
            name: "fetchAmount",
            requied: "requied",
            value: getReturnInputField?.fetchAmount ? getReturnInputField?.fetchAmount : ''
        },

        {
            id: 2,
            label: "Bring Price",
            palceHolder: "Enter Bring Price",
            type: "number",
            name: "bringAmount",
            requied: "requied",
            value: getReturnInputField?.bringAmount ? getReturnInputField?.bringAmount : '',
        },
        {
            id: 3,
            label: "Currency",
            palceHolder: "Select Currency",
            type: "select",
            name: "currency",
            requied: "requied",
            value: getReturnInputField?.currency ? getReturnInputField?.currency : 'eur',
            selector: {
                height: "100%",
                padding: "10px",
                maxWidth: "100%",
                marginTop: "1em",
                width: "calc(100% - 330px)",
            },
            option: [
                { id: 1, value: 'eur', name: "Euro" }
            ],
        },

    ];

    const DeliveryServiceInputsObj = [

        {
            id: 1,
            label: "Fetch Price",
            palceHolder: "Enter Fetch Price",
            type: "number",
            name: "fetchAmount",
            requied: "requied",
            value: getDeliveryInputField?.fetchAmount ? getDeliveryInputField?.fetchAmount : ''
        },

        {
            id: 2,
            label: "Bring Price",
            palceHolder: "Enter Bring Price",
            type: "number",
            name: "bringAmount",
            requied: "requied",
            value: getDeliveryInputField?.bringAmount ? getDeliveryInputField?.bringAmount : '',
        },

        {
            id: 3,
            label: "Currency",
            palceHolder: "Select Currency",
            type: "select",
            name: "currency",
            value: getDeliveryInputField?.currency ? getDeliveryInputField?.currency : 'eur',
            requied: "requied",
            selector: {
                height: "100%",
                padding: "10px",
                maxWidth: "100%",
                marginTop: "1em",
                width: "calc(100% - 330px)",
            },
            option: [
                { id: 1, value: 'eur', name: "Euro" }
            ],
        },

    ];

    const register = useSelector(({ registerReducer }) => registerReducer);

    const handleReturnInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setReturnInputField({ ...getReturnInputField, [name]: value });
    };

    const handleDeliveryInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDeliveryInputField({ ...getDeliveryInputField, [name]: value });
    };

    const handleclick = (e) => {
        if (!getReturnInputField.bringAmount || !getReturnInputField.fetchAmount || !getReturnInputField.currency) {
            swal("Error!", "all field is required!", "error");
            return;
        }
        if (getReturnInputField.bringAmount.length > 4 || getReturnInputField.fetchAmount.length > 4) {
            swal("Error!", "Enter valid amount!", "error");
            return;
        }
        if (!getDeliveryInputField.bringAmount || !getDeliveryInputField.fetchAmount || !getDeliveryInputField.currency) {
            swal("Error!", "all field is required!", "error");
            return;
        }
        if (getDeliveryInputField.bringAmount.length > 4 || getDeliveryInputField.fetchAmount.length > 4) {
            swal("Error!", "Enter valid amount!", "error");
            return;
        }
        e.preventDefault();
        const data = { "Return": getReturnInputField, "Delivery": getDeliveryInputField }
        dispatch(updateServiceAmount(data));
    };
    const location = useLocation();
    
    return (
        <>
            {register.loading && (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                </Grid>
            )}
            <NavBar title="Settings" url={location.pathname} />

            <div className="accordion" id="generalSettings">
                <div className="accordion-item border-0 border-top formBox mb-3 p-0 border-top-0">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button bg-white pb-4 pt-4 collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h2 className="font-29 mb-0 font-weight-700" style={{ color: "#1F4173" }}>Price Setting</h2>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#generalSettings">

                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="font-23 font-weight-700 pt-2 pb-3" style={{ color: "#1F4173" }}>Return Service</h2>
                                    <div className="row">
                                        {ReturnServiceInputsObj.map((val) => {
                                            const cl = `col-lg-${val.type === "file" ? 12 : 6} col-sm-12`;
                                            return (
                                                <div className={cl} key={val.id}>
                                                    <div className="mb-4">
                                                        <InputField
                                                            id={val.id}
                                                            label={val.label}
                                                            type={val.type}
                                                            placeholder={val.palceHolder}
                                                            option={val.option}
                                                            handleChange={handleReturnInputChange}
                                                            name={val.name}
                                                            requied={val.requied}
                                                            classStyle={classes}
                                                            value={val.value}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="font-23 font-weight-700 pt-2 pb-3" style={{ color: "#1F4173" }}>Delivery Service</h2>
                                    <div className="row">
                                        {DeliveryServiceInputsObj.map((val) => {
                                            const cl = `col-lg-${val.type === "file" ? 12 : 6} col-sm-12`;
                                            return (
                                                <div className={cl} key={val.id}>
                                                    <div className="mb-4">
                                                        <InputField
                                                            id={val.id}
                                                            label={val.label}
                                                            type={val.type}
                                                            placeholder={val.palceHolder}
                                                            option={val.option}
                                                            handleChange={handleDeliveryInputChange}
                                                            name={val.name}
                                                            requied={val.requied}
                                                            classStyle={classes}
                                                            value={val.value}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-body">
                            <div className="row mt-5 pt-5 mb-5">
                                <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                            <CustomButton
                                                className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                                type="submit"
                                                style={{
                                                    background: "#003AD2",
                                                    borderRadius: "8px",
                                                    width: "290px",
                                                    height: "55px",
                                                    fontSize: "26px"
                                                }}
                                                onClick={handleclick}
                                            >
                                                Save
                                            </CustomButton>
                                        </div>

                                        <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                            <CustomButton
                                                style={{
                                                    color: "#1F4173",
                                                    width: "140px !important ",
                                                    textTransform: "capitalize",
                                                    height: "55px",
                                                    fontWeight: 700,
                                                    fontSize: "26px"
                                                }}
                                                onClick={() => navigate("/")}>
                                                Cancel
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default ServiceAmountUpdateForm;
