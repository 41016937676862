import {
    GoogleMap,
    LoadScript,
    Marker,
    useLoadScript,
} from "@react-google-maps/api";
import { Box, Grid, makeStyles } from "@material-ui/core";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../components/custom/navBar";
import MakerIcon from "../assets/images/icon.svg"
import { fetchLocalkingsForMapList } from "../redux/user/action";

const useStyles = makeStyles({
    component: {
        display: "flex",
        flexDirection: "column",
        position: "relative ",
    },
});

export const MapContainer = () => {
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const Dispatch = useDispatch()

    const classes = useStyles();
    const localkings = useSelector(
        ({ fetchLocalKingForMapListReducer }) => fetchLocalKingForMapListReducer.items
    );
    
    const location =
        localkings?.data &&
        localkings?.data?.length &&
        localkings?.data?.map((item) => {
            return {
                id: item?._id,
                name: item?.firstName,
                icon: item?.image,
                location: {
                    lng: Number(item?.location?.coordinates[0]),
                    lat: Number(item?.location?.coordinates[1]),
                },
            };
        });
    useEffect(() => {
        Dispatch(fetchLocalkingsForMapList())
        navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        });
    }, []);

    const mapStyles = {
        height: "100vh",
        width: "100%",
        borderRadius:"15px"
    };

    const defaultCenter = {
        lat: lat,
        lng: lng,
    };
    const locations = useLocation();

    return (
        <>
            <Layout>
                <Box className={classes.component}>
                    <Box>
                        {localkings.loading && (
                            <Grid
                                container
                                justifyContent="center"
                                style={{
                                    height: "100vh",
                                    width: "100%",
                                    position: "absolute",
                                }}
                                alignItems="center"
                            >
                                <ReactLoading type="spinningBubbles" color="blue" />
                            </Grid>
                        )}
                        <NavBar title="Map" url={locations.pathname} />
                        <div
                            style={{
                                borderRadius: "15px",
                                boxShadow: "0px 4px 90px rgba(163, 171, 185, 0.24",
                            }}
                        >
                            {" "}
                            <div style={{ padding: "40px 40px" }}>
                                <h4 className="font-29 mb-3 font-weight-700" style={{ color: "rgb(31, 65, 115)" }}>
                                    Localkings Map View
                                </h4>
                                <LoadScript
                                    googleMapsApiKey={"AIzaSyAj8rg7FBxhrplJLEcWTjJxxlCQ6F1GcME"}
                                >
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        zoom={13}
                                        center={defaultCenter}
                                    >
                                        {location && location.length && location.map((item) => {
                                            return (
                                                <Marker
                                                    key={item.id}
                                                    position={item.location}
                                                    title={item.name}
                                                    icon={MakerIcon}
                                                />
                                            );
                                        })}
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Layout>
        </>
    );
};
