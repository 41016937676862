import React, { useEffect, useState } from "react";
import { Badge } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { PhotoCamera } from "@material-ui/icons";
import { useSelector } from "react-redux";

export default function Imageselector({ imgUrl, handleChangeImage, disabled }) {
    const Input = styled("input")({
        display: "none",
    });

    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
                <label htmlFor="icon-button-file">
                    <Input
                        // accept="image/*"
                        accept="image/png, image/jpeg"
                        name="image"
                        onChange={handleChangeImage}
                        id="icon-button-file"
                        type="file"
                        disabled={disabled}
                    />

                    <div style={{
                        width: "80px",
                        height: " 80px",
                        background: "#F5F7FB",
                        border: "8px solid #FFFFFF",
                        borderRadius: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: 'pointer'
                    }}>
                        <PhotoCamera style={{ color: "#003AD2", background: "transparent", }} />
                    </div>
                </label>
            }
        >
            <Avatar
                style={{ width: "230px", height: "230px" }}
                src={imgUrl}
            />
        </Badge>
    );
}
