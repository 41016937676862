import AddCustomer from "../pages/AddCustomer";
import AddProfile from "../pages/Profile";
import ChangeEmailAddress from "../pages/changeemailAdd";
import ChangeMobileNumber from "../pages/changeMobileNumber";
import Dashboard from "../pages/Dashboard";
import EditCustomer from "../pages/EditCustomer";
import EditProfile from "../pages/EditProfile";
import ListCustomer from "../pages/ListCustomer";
import ListLocalking from "../pages/ListLocalking";
import ListPayments from "../pages/ListPayments";
import Payout from "../pages/payoutsetting";
import Transaction from "../pages/Transaction";
import Login from "../pages/Login";
import { MapContainer } from "../pages/map";
import ViewTranaction from "../pages/viewTransaction";
import AddCoupon from "../pages/AddCoupon";
import CouponList from "../pages/CouponList";
import EditCoupon from "../pages/EditCoupon";
import GeneralSettings from "../pages/GeneralSettings";
import PaymentSetting from "../pages/Payment-Setting";
import AddWalkInScreen from "../pages/AddWalkInScreen";
import WalkInScreenList from "../pages/WalkInScreenList";
import EditWalkInScreen from "../pages/EditWalkInScreen";
import ForgotPasswordScreen from "../pages/ForgotPasswordScreen";
import VerifyOtp from "../pages/VerifyOtp";
import ResetPassword from "../pages/ResetPassword";
import Notifications from "../pages/Notifications";
import PayoutDetails from "../pages/PayoutDetails";

const protectedRoutes = [
    {
        id: 0,
        title:"Login",
        path: "/login",
        data: <Login />,
        isProtected: false,
    },
    {
        id: 1,
        title:"Dashboard",
        path: "/",
        data: <Dashboard />,
        isProtected: true,
    },
    {
        id: 2,
        title:"Users",
        path: "/users/List-of-Customers",
        data: <ListCustomer />,
        isProtected: true,
    },
    {
        id: 3,
        title:"Add Customer",
        path: "/users/List-of-Customer/add-Customer",
        data: (
            <AddCustomer
                NameOfuser={"Add-Customer"}
                role={["Customer", "LocalKing", "Admin"]}
            />
        ),
        isProtected: true,
    },
    {
        id: 4,
        title:"List Localking",
        path: "/users/list-of-localkings",
        data: <ListLocalking />,
        isProtected: true,
    },
    {
        id: 5,
        path: "/users/list-localkings/Add-Localking",
        data: (
            <AddCustomer
                NameOfuser={"Add-LocalKing"}
                role={["LocalKing", "Customer", "Admin"]}
            />
        ),
        isProtected: true,
    },
    {
        id: 6,
        title:"Transaction",
        path: "/transactions",
        data: <Transaction />,
        isProtected: true,
    },
    {
        id: 7,
        title:"View Tranaction",
        path: "/transactions/view-transaction",
        data: <ViewTranaction />,
        isProtected: true,
    },
    {
        id: 8,
        title:"Payout list",
        path: "/payout-list",
        data: <ListPayments />,
        isProtected: true,
    },
    {
        id: 9,
        title:'Edit Customer',
        path: "/users/List-of-Customers/edit-customer",
        data: <EditCustomer />,
        isProtected: true,
    },
    {
        id: 10,
        title:"Add Profile",
        path: "Settings/profile",
        data: <AddProfile />,
        isProtected: true,
    },
    {
        id: 11,
        title:"Edit Profile",
        path: "/Settings/profile/edit-profile",
        data: <EditProfile />,
        isProtected: true,
    },
    {
        id: 12,
        title:"Payout",
        path: "/payout-settings",
        data: <Payout />,
        isProtected: true,
    },
    {
        id: 13,
        title:"Change Email Address",
        path: "/Settings/Change-Email-Address",
        data: <ChangeEmailAddress />,
        isProtected: true,
    },
    {
        id: 14,
        title:"Change Mobile Number",
        path: "/Settings/Change-Mobile-Number",
        data: <ChangeMobileNumber />,
        isProtected: true,
    },
    {
        id: 15,
        title:"Map",
        path: "/map",
        data: <MapContainer />,
        isProtected: true,
    },
    {
        id: 16,
        title:"Edit Customer",
        path: "/users/list-localkings/edit-localKing",
        data: <EditCustomer />,
        isProtected: true,
    },
    {
        id: 17,
        title:"Coupon list",
        path: "/coupon-list",
        data: <CouponList />,
        isProtected: true,
    },
    {
        id: 18,
        title:"Add Coupon",
        path: "/add-Coupon",
        data: <AddCoupon />,
        isProtected: true,
    },
    {
        id: 16,
        title:"Edit Coupon",
        path: "/coupon-list/edit-Coupon",
        data: <EditCoupon />,
        isProtected: true,
    },
    {
        id: 19,
        title:"General Settings",
        path: "/settings/general-settings",
        data: <GeneralSettings />,
        isProtected: true,
    },
    {
        id: 19,
        title:"Payment Setting",
        path: "/settings/payment-setting",
        data: <PaymentSetting />,
        isProtected: true,
    },
    {
        id: 19,
        title:"List of WalkIn Screens",
        path: "/list-of-walk-in-screens/",
        data: <WalkInScreenList />,
        isProtected: true,
    },
    {
        id: 19,
        title:"Add WalkIn Screen",
        path: "/add-walk-in-screen/",
        data: <AddWalkInScreen />,
        isProtected: true,
    },
    {
        id: 20,
        title:"Walk-in Screens",
        path: "/list-of-walk-in-screen/edit-walk-in-screen/",
        data: <EditWalkInScreen />,
        isProtected: true,
    },
    {
        id: 21,
        title:"Forgot Password Screen",
        path: "/forgotPassword",
        data: <ForgotPasswordScreen />,
        isProtected: false,
    },
    {
        id: 22,
        title:"Verify Otp",
        path: "/verifyOtp",
        data: <VerifyOtp />,
        isProtected: false,
    },
    {
        id: 23,
        title: "Reset Password",
        path: "/resetPassword",
        data: <ResetPassword />,
        isProtected: false,
    },
    {
        id: 24,
        title: "Notifications List",
        path: "/notifications",
        data: <Notifications />,
        isProtected: true,
    },
    {
        id: 25,
        title: "Payout details",
        path: "/payout-list/pay-now",
        data: <PayoutDetails/>,
        isProtected: true,
    },
];

export default protectedRoutes;
