import "./App.css";
import { Route, Routes } from "react-router-dom";
import Protection from "./routes/Protection";
import protectedRoutes from "./routes/RoutePath";
import { makeStyles, Box, Grid } from "@material-ui/core";
import Sidebar from "./components/sidebar/Sidebar";
const useStyles = makeStyles((theme) => ({
    component: {
        width: "100vw",
    },
    leftComponent: {
        "@media (min-width: 1280px)": {
            minWidth: 325,
        },
    },

    rightComponent: {
        height: "100%",
        padding: "20px",
        maxWidth: "100%",
        marginTop: "1em",
        width: "calc(100% - 363px)",
        marginLeft: "345px",
    },
}));

function App() {
    const classname = useStyles();
    return (
        <div className="app">
            <Routes>
                {protectedRoutes.map((route) => (
                    <Route
                        keys={route.id}
                        path={route.path}
                        element={
                            route.isProtected ? (
                                <>
                                    <Grid container>
                                        <Box className={classname.leftComponent}>
                                            <Sidebar />
                                        </Box>
                                        <Protection>{route.data}</Protection>
                                    </Grid>
                                </>
                            ) : (
                                route.data
                            )
                        }
                    />
                ))}
            </Routes>
        </div>
    );
}

export default App;
