import InputLabel from "@mui/material/InputLabel";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { fetchOrder } from "../../redux/user/action"; 
import axios from "axios";

const NewIcon = () => (
    <svg width="12" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.49886 6.53981L0.255859 2.29681L1.67086 0.882813L4.49886 3.71181L7.32686 0.882813L8.74186 2.29681L4.49886 6.53981Z" fill="#1F4173" />
    </svg>

);
export default function Selector({ setfilterVal }) {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setfilterVal(event.target.value)
        // dispatch(fetchLocalkings(event.target.value));
        dispatch(fetchOrder(event.target.value));

    };

    return (
        <FormControl
            className="min-width-custom"
            variant="standard"
            sx={{ minWidth: 200 }}
            hiddenLabel={true}
        >
            <InputLabel sx={{fontSize: "13px",fontWeight: "300"}} >Filter</InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleChange}
                IconComponent={NewIcon}
                sx={{fontSize: "13px",fontWeight: "300"}}
            >
                {/* <MenuItem value="" disabled selected>Select Filter </MenuItem> */}
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="thisWeek">Weekly</MenuItem>
                <MenuItem value="last30Days">Monthly</MenuItem>
            </Select>
        </FormControl>
    );
}
