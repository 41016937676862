
import ServiceAmountUpdateForm from "../components/forms/ServiceAmountUpdateForm"
import Layout from "../layout";

export default function GeneralSettings() {
    return (
        <Layout>
            <ServiceAmountUpdateForm />
        </Layout>
    )
}
