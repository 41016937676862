import Popover from "@mui/material/Popover";
import activeNotification from "../../assets/images/activeNotifivation.svg";
import notification from "../../assets/images/notifivation.svg";
import activeNotificationdot from "../../assets/images/activeNotificationdot.svg";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import "./notification.css";
import { useDispatch, useSelector } from "react-redux";
import {  getUnReadNotification, readNotification } from "../../redux/user/action";
import { formatDateAndTime } from "../custom/formatDateAndTime";
import { useNavigate } from "react-router-dom";

function Notification() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [dbvalue, setDbvalue] = useState(null);
    const [filterCountNumber] = useState(1)
    const [filter, setFilter] = useState(null)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUnReadNotification( filterCountNumber, dbvalue,false))
    }, [filterCountNumber]);

    const getUnreadNotifications = useSelector(
        ({ getUnreadNotificationReducer }) => getUnreadNotificationReducer.items.data
    );


    const navigate = useNavigate();

    const handleClick = (event) => {
        dispatch(getUnReadNotification(filterCountNumber, dbvalue,false))
        setAnchorEl(event.currentTarget);
    };

    const handelClickNotifications = (id) => {
        dispatch(readNotification(id))
        navigate("/notifications",);
    };
    const handelClickSeeAll = () => {
        navigate("/notifications",);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div className="notification_main">
            <div className="notification_icons">
                <IconButton onClick={handleClick} className="p-0">
                    {
                        getUnreadNotifications?.length == 0 || getUnreadNotifications?.length == undefined ? (
                            <img src={notification}
                                className="active_notification_icon"
                                alt="notification icon" />
                        ) : (
                            <img
                                src={activeNotification}
                                className="active_notification_icon"
                                alt="notification icon"/>
                        )
                    }
                </IconButton>
                <div className="poper">
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            style: { 
                                height: "418px", 
                                width: "360px", 
                                overflow:"inherit",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                                borderRadius:" 1px"

                             },
                        }}>
                        <div className="notification__container">

                            <div className="notification__header-container">
                                <h2 className="notification__header">Notifications</h2>
                            </div>

                            <div className="notification__list-main">
                                {getUnreadNotifications !== undefined ? getUnreadNotifications.map((data, index) => (
                                    <div className="notification__list" key={index} onClick={()=>handelClickNotifications(data._id)}>
                                        <div className="notification__list-header-container">
                                            <h2 className="notofication__list-header">
                                                {data.localKing_title}
                                            </h2>
                                            <p className="notofication__list-time mb-0">
                                                {formatDateAndTime(data.time, "time")}
                                            </p>
                                        </div>
                                        <div className="notification__list-contant-container">
                                            <p className="notofication__list-content">
                                                {data.localKing_description}
                                            </p>
                                            <div className="notofication__list-icon ">
                                                {!data?.isRead ? <img
                                                    src={activeNotificationdot}
                                                    className="active_notification_dot"
                                                    alt="notofication icon"
                                                /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                )) : null
                                }
                            </div>

                            <div className="notification__btn-conatiner">
                                <h2 className="see_all-btn" onClick={handelClickSeeAll}>
                                    See All
                                </h2>
                            </div>

                        </div>
                    </Popover>
                </div>
            </div>
        </div>
    );
}

export default Notification;
