import {
  Box,
  CircularProgress,
  Hidden,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import NavBar from "../custom/navBar";
import Paginate from "./Paginate";
import Body from "./TableBody";
import TableHeader from "./TableHeader";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ControlPointSharp, ExpandLess, ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import "./table.modules.css";
import { fetchOrder } from "../../redux/user/action";

export default function BasicTable({
  tableHead,
  tableBody,
  paginate,
  type,
  name,
  subtitle,
  setfilterVal,
  dbvalue,
  filtervalue,
  customer_loading,
  filtercount,
  TableFilterCoun,
  NavigateLocation,
  setFilterCount,
  setSearchData,
  isLoadingData,
  tableTitle,
  initCurrentPage = 0,
  filterCountNumber,
  search
}) {
  const [getFromHandleShortIcons, setFromHandleShortIcons] = useState(false);
  const [getToHandleShortIcons, setToHandleShortIcons] = useState(false);
  
  const useStyles = makeStyles({
    title: {
      fontWeight: "700",
      fontSize: "29px",
      color: "#1F4173",
      marginBottom: "25px",
      padding: "25px 0px 0px 28px",
    },

    from: {
      "& .expandLess": {
        display: getFromHandleShortIcons ? "none" : "inline-block",
      },
      "& .expandMore": {
        display: getFromHandleShortIcons ? "inline-block" : "none",
      },
    },
    to: {
      "& .expandLess": {
        display: getToHandleShortIcons ? "none" : "inline-block",
      },
      "& .expandMore": {
        display: getToHandleShortIcons ? "inline-block" : "none",
      },
    },
  });
  const classes = useStyles();
  const location = useLocation();
  const [getPage, setPage] = useState(initCurrentPage);
  // const [filterTime, setFilterTime] = useState(null);
  const [currentPage, setCurrentPage] = useState(getPage);
  const [DatasPerPage] = useState(10);
  let current_page = getPage;
  const tableBodyMap = tableBody?.data || tableBody;
  const Dispatch = useDispatch();

  const handlePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const callbackData = (page) => {
    setPage(page);
  };


  const handelSort = (e) => {
    if (e.target.id == 5 && getFromHandleShortIcons == false) {
      Dispatch(fetchOrder(null, current_page, null, "from"));
      setFromHandleShortIcons(true);
    } else {
      Dispatch(fetchOrder(null, current_page, null, null));
      setFromHandleShortIcons(false);
    }

    if (e.target.id == 6  && getToHandleShortIcons == false) {
      Dispatch(fetchOrder(null, current_page, null, "to"));
      setToHandleShortIcons(true);
    } else {
      Dispatch(fetchOrder(null, current_page, null, null));
      setToHandleShortIcons(false);
    }
  };

  return (
    <>
      {type === "dashboard" ? null : (
        <NavBar title={name} subtitle={subtitle} url={location?.pathname} />
      )}
      <div className="tableBox pt-3">
        {type === "dashboard" ? (
          <Box className={classes.title}>{tableTitle}</Box>
        ) : null}

        {type === "dashboard" ? null : (
          <TableHeader
            title={tableTitle}
            setfilterVal={setfilterVal}
            passData={setSearchData}
            NavigateLocation={NavigateLocation}
            extra={
              type === "localkingList"
                ? "add"
                : type === "customerList"
                ? "add"
                : type === "transaction"
                ? "select"
                : type === "paymentList"
                ? "export"
                : type === "coupon-list"
                ? ""
                : null
            }
            type={type}
            data={tableBodyMap}
            search={search}
          />
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHead.map((item) => (
                  <TableCell
                    onClick={item.sortBy ? (e) => handelSort(e) : () => {}}
                    id={`${item.id}`}
                    key={item.id}
                    className={`font-weight-600 primary-color ${
                      item.id == 5
                        ? classes.from
                        : item.id == 6
                        ? classes.to
                        : ""
                    }`}
                  >
                    {item.title}
                    {item.sortBy && (
                      <>
                        <ExpandLess className={`expandLess`} />
                        <ExpandMore className={`expandMore`} />
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ display: "flex", justifyContent: "center" }}>
              {" "}
              {dbvalue && filtercount && filtercount?.length == 0
                ? null
                : (tableBodyMap?.length !== 0 && type == "dashboard") ||
                  type == "paymentList" ||
                  tableBodyMap?.length == 0
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      type={type}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : tableBodyMap?.length !== 0 && type == "transaction"
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : tableBodyMap?.length !== 0 && type == "walkinList"
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      type={type}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : tableBodyMap?.length != 0 && type == "couponList"
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      type={type}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : tableBodyMap?.length != 0 && type == "notifications"
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      type={type}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : tableBodyMap?.length !== 0 &&
                  filtercount?.length == 0 &&
                  type == "customerList"
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      type={type}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1

                        // lc_pageCount != null
                        // ? lc_pageCount > 1
                        // : current_page > 1
                        // ? lc_pageCount != null ? index + 1 + lc_pageCount * getPage - 10 : index + 1 + DatasPerPage * getPage - 10
                        // : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : tableBodyMap?.length !== 0 &&
                  filtercount?.length == 0 &&
                  type == "localkingList"
                ? tableBodyMap.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      type={type}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))
                : dbvalue !== null &&
                  filtercount &&
                  filtercount?.length !== 0 &&
                  filtercount.map((item, index) => (
                    <Body
                      key={item.id}
                      row={item}
                      itemIndex={
                        current_page > 1
                          ? index + 1 + DatasPerPage * getPage - 10
                          : index + 1
                      }
                      currentPage={current_page}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        { !isLoadingData && dbvalue !== null &&
          filtercount &&
          filtercount?.length == 0 &&
           (
              <div
                style={{
                  height: "330px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "rgb(31, 65, 115)",
                }}
              >
                <h4>NO DATA FOUND</h4>
              </div>
            )}

        {tableBodyMap?.length == 0 ||
        (isLoadingData &&
          dbvalue !== null &&
          filtercount &&
          filtercount?.length == 0 && (
            <div
              style={{
                height: "330px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "rgb(31, 65, 115)",
              }}
            >
              <CircularProgress />
            </div>
          ))}
      </div>
      <div className="row mt-3">
        <div className="col-md-12 text-end">
          {paginate && (
            <Paginate
              totalData={tableBody?.count}
              filtercount={filtercount?.totalCount}
              totalItems={tableBody?.count}
              itemsPerPage={DatasPerPage}
              pageSelected={handlePage}
              currentPage={current_page}
              dbvalue={dbvalue}
              TableFilterCoun={TableFilterCoun}
              setFilterCount={setFilterCount}
              callbackData={()=>{}}
              onChange={(currentPage)=>callbackData(currentPage)}
              getPage={getPage}
              filtervalue={filtervalue}
              filterCountNumber={filterCountNumber}
            />
          )}
        </div>
      </div>
    </>
  );
}
