import React from "react";
import AddCouponForm from "../components/forms/addCoupon";
import Layout from "../layout";

const AddCoupon = () => {
    return (
        <Layout>
            <AddCouponForm />
        </Layout>
    );
};

export default AddCoupon;
