import ChangeemailAdd from "../components/forms/changeemailAdd";
import Layout from "../layout";

export default function ChangeEmailAddress() {
  return (
    <Layout>
      <ChangeemailAdd />
    </Layout>
  );
}
