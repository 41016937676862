import * as React from 'react'; 
import Dialog from '@mui/material/Dialog'; 
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText'; 
import { makeStyles } from '@material-ui/core'; 
import check from "../../../src/assets/images/Check.png";

const useStyles = makeStyles({
    p: {
        color: "#1F7900",
        textAlign: "center",
        fontWeight: 400,
        fontSize: " 23px"
    },
});

export default function VerifiedOTPDialog({ title, buttonTitle, className }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
     
    return (
        <div>
            <Dialog open={open} className={className}>
                <DialogContent className='p-0'>
                    <img src={check} alt="" className='d-block m-auto mb-5' />
                    <DialogContentText className='text-center  text-dark font-18'>
                        <p className={classes.p}>Your {localStorage.getItem("type") == "email" ? " mail ":" mobile number "} has been verified and changed successfully. </p>
                        {/* <p className={`${classes.p} mb-0`}>Too verify your new email kindly login again with the new password sent to your new email <span className='font-weight-700'>
                            {localStorage.getItem("email")}
                        </span>
                        </p> */}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}