import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAdminEmail, verifyOtp, verifyOtpSetinitialStateFalse } from "../../redux/auth/action";
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import { Link, Navigate, useNavigate } from "react-router-dom";
import localkings from "../../assets/images/icon.svg";
import { Grid } from "@mui/material";


export default function VerifyOtpForm() {

    const useStyles = makeStyles({
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
        },
        label: {
            height: "100%",
            padding: "10px",
            maxWidth: "100%",
            marginTop: "1em",
            width: "calc(100% - 330px)",
            fontWeight: 500,
            fontSize: "15px",
        },
        button: {
            background: "rgb(0, 58, 210)",
            width: "100%",
            height: "40px",
            border: "none",
            borderRadius: "5px",
            color: "white",
            marginTop: "10px",
            fontWeight: 600,
        },
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getInputField, setInputField] = useState("");
    const currentUser = useSelector(({ loginReducer }) => loginReducer);

    useEffect(() => {
        dispatch(verifyOtpSetinitialStateFalse());
    }, []) 

    const verifyOtpReducer = useSelector(state => state.verifyOtpReducer);
    if (verifyOtpReducer.isVerified === true) {
        localStorage.setItem("otp", getInputField)
        navigate('/resetPassword');
    }
    

    const InputsObj = [
        {
            id: 1,
            label: "OTP",
            palceHolder: "Enter Otp",
            type: "number",
            name: "opt",
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            otp: getInputField,
            loginType: localStorage.getItem("loginType"),
        };

        dispatch(verifyOtp(data)); 
    };

    return (
        <>
            {currentUser.loading ? (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h2 style={{ color: "blue", marginRight: "20px" }}>
                            Please Wait...
                        </h2>
                        <CircularProgress style={{ color: "blue" }} />
                    </div>
                </Grid>
            ) : (
                <>
                    <div id="main" className="formbox verify_otp_form">
                        <div className="box">
                            <div className="font-25 font-we text-center mb-4">
                                <img src={localkings} alt="" />
                                <span className="d-block mt-2 font-weight-700">LocalKings</span>
                            </div>
                            <div className="font-we text-center mb-2">
                                <span className=" font-25 d-block mt-2 font-weight-700">
                                    OTP Verification
                                </span>
                                <p className="my-3" style={{ fontSize: "small" }}>
                                    Please enter the otp sent to your registered email address or
                                    registered Mobile number for verification.
                                </p>
                            </div>

                            <form onSubmit={handleSubmit}>
                            
                                <div className="col-lg-12 col-sm-12">
                                    {InputsObj.map((val) => {
                                        const cl = `col-lg-${val.type === "file" ? 12 : 12
                                            } col-sm-12`;
                                        return (
                                            <div className={cl} keys={val.id}>
                                                <InputField
                                                    id={val.id}
                                                    label={val.label}
                                                    type={val.type}
                                                    placeholder={val.palceHolder}
                                                    option={val.option}
                                                    handleChange={handleChange}
                                                    name={val.name}
                                                    classStyle={classes}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>

                                { verifyOtpReducer.error !== null ? 
                                    <div className="col-md-12">
                                        <span className="font-15 font-weight-500 d-block mt-2" style={{color:" #AF2828",}}>
                                        {verifyOtpReducer.error}
                                        </span>
                                    </div>
                                : null
                                }

                                <div style={{ display: "flex", marginTop: "20px" }}>
                                    <CustomButton type="submit" className={classes.button}>
                                        Verify
                                    </CustomButton>
                                </div>

                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
