const userActionTypes = {
    CUSTOMER_LIST_FAILURE: "CUSTOMER_LIST_FAILURE",
    CUSTOMER_LIST_SUCCESS: "CUSTOMER_LIST_SUCCESS",
    CUSTOMER_LIST_REQUEST: "CUSTOMER_LIST_REQUEST",

    CUSTOMER_FILTER_LIST_FAILURE: "CUSTOMER_FILTER_LIST_FAILURE",
    CUSTOMER_FILTER_LIST_SUCCESS: "CUSTOMER_FILTER_LIST_SUCCESS",
    CUSTOMER_FILTER_LIST_REQUEST: "CUSTOMER_FILTER_LIST_REQUEST",

    LOCALKING_LIST_FOR_MAP_LIST_REQUEST: "LOCALKING_LIST_FOR_MAP_LIST_REQUEST",
    LOCALKING_LIST_FOR_MAP_LIST_SUCCESS: "LOCALKING_LIST_FOR_MAP_LIST_SUCCESS",
    LOCALKING_LIST_FOR_MAP_LIST_FAILURE: "LOCALKING_LIST_FOR_MAP_LIST_FAILURE",

    LOCALKING_LIST_REQUEST: "LOCALKING_LIST_REQUEST",
    LOCALKING_LIST_SUCCESS: "LOCALKING_LIST_SUCCESS",
    LOCALKING_LIST_FAILURE: "LOCALKING_LIST_FAILURE",

    LOCALKING_FILTER_LIST_FAILURE: "LOCALKING_FILTER_LIST_FAILURE",
    LOCALKING_FILTER_LIST_SUCCESS: "LOCALKING_FILTER_LIST_SUCCESS",
    LOCALKING_FILTER_LIST_REQUEST: "LOCALKING_FILTER_LIST_REQUEST",

    TRANSACTION_LIST_REQUEST: "TRANSACTION_LIST_REQUEST",
    TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
    TRANSACTION_LIST_FAILURE: "TRANSACTION_LIST_FAILURE",

    PAYMENT_LIST_REQUEST: "PAYMENT_LIST_REQUEST",
    PAYMENT_LIST_SUCCESS: "PAYMENT_LIST_SUCCESS",
    PAYMENT_LIST_FAILURE: "PAYMENT_LIST_FAILURE",

    ORDER_LIST_REQUEST: "ORDER_LIST_REQUEST",
    ORDER_LIST_SUCCESS: "ORDER_LIST_SUCCESS",
    ORDER_LIST_FAILURE: "ORDER_LIST_FAILURE",

    ORDER_FIVE_REQUEST: "ORDER_FIVE_REQUEST",
    ORDER_FIVE_SUCCESS: "ORDER_FIVE_SUCCESS",
    ORDER_FIVE_FAILURE: "ORDER_FIVE_FAILURE",

    ORDER_CANCEL_REQUEST: "ORDER_CANCEL_REQUEST",
    ORDER_CANCEL_SUCCESS: "ORDER_CANCEL_SUCCESS",
    ORDER_CANCEL_FAILURE: "ORDER_CANCEL_FAILURE",

    DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

    GET_SERVICE_REQUEST: "GET_SERVICE_REQUEST",
    GET_SERVICE_SUCCESS: "GET_SERVICE_SUCCESS",
    GET_SERVICE_FAILURE: "GET_SERVICE_FAILURE",
    PAYOUT_LIST_REQUEST: "PAYOUT_LIST_REQUEST",
    PAYOUT_LIST_SUCCESS: "PAYOUT_LIST_SUCCESS",
    PAYOUT_LIST_FAILURE: "PAYOUT_LIST_FAILURE",

    SCHEDULE_PAYOUT_LIST_REQUEST: "SCHEDULE_PAYOUT_LIST_REQUEST",
    SCHEDULE_PAYOUT_LIST_SUCCESS: "SCHEDULE_PAYOUT_LIST_SUCCESS",
    SCHEDULE_PAYOUT_LIST_FAILURE: "SCHEDULE_PAYOUT_LIST_FAILURE",

    GET_SCHEDULE_PAYOUT_LIST_REQUEST: "GET_SCHEDULE_PAYOUT_LIST_REQUEST",
    GET_SCHEDULE_PAYOUT_LIST_SUCCESS: "GET_SCHEDULE_PAYOUT_LIST_SUCCESS",
    GET_SCHEDULE_PAYOUT_LIST_FAILURE: "GET_SCHEDULE_PAYOUT_LIST_FAILURE",

    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
    EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

    ORDER_REVIEW_REQUEST: "ORDER_REVIEW_REQUEST",
    ORDER_REVIEW_SUCCESS: "ORDER_REVIEW_SUCCESS",
    ORDER_REVIEW_FAILURE: "ORDER_REVIEW_FAILURE",

    ORDER_REVIEW_DELETE_REQUEST: "ORDER_REVIEW_DELETE_REQUEST",
    ORDER_REVIEW_DELETE_SUCCESS: "ORDER_REVIEW_DELETE_SUCCESS",
    ORDER_REVIEW_DELETE_FAILURE: "ORDER_REVIEW_DELETE_FAILURE",

    SINGLE_USER_REQUEST: "SINGLE_USER_REQUEST",
    SINGLE_USER_SUCCESS: "SINGLE_USER_SUCCESS",
    SINGLE_USER_FAILURE: "SINGLE_USER_FAILURE",

    IS_ACTIVE_USER_SUCCESS: "IS_ACTIVE_USER",
    IS_ACTIVE_USER_REQUEST: "IS_ACTIVE_USER_REQUEST",
    IS_ACTIVE_USER_FAILUR: "IS_ACTIVE_USER_FAILUR",

    CREATE_COUPON_FAILURE: "CREATE_COUPON_FAILURE",
    CREATE_COUPON_SUCCESS: "CREATE_COUPON_SUCCESS",
    CREATE_COUPON_REQUEST: "CREATE_COUPON_REQUEST",

    COUPON_LIST_FAILURE: "COUPON_LIST_FAILURE",
    COUPON_LIST_SUCCESS: "COUPON_LIST_SUCCESS",
    COUPON_LIST_REQUEST: "COUPON_LIST_REQUEST",

    DELETE_COUPON_REQUEST: "DELETE_COUPON_REQUEST",
    DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
    DELETE_COUPON_FAILURE: "DELETE_COUPON_FAILURE",

    SINGLE_COUPON_REQUEST: "SINGLE_COUPON_REQUEST",
    SINGLE_COUPON_SUCCESS: "SINGLE_COUPON_SUCCESS",
    SINGLE_COUPON_FAILURE: "SINGLE_COUPON_FAILURE",

    UPDATE_COUPON_REQUEST: "UPDATE_COUPON_REQUEST",
    UPDATE_COUPON_SUCCESS: "UPDATE_COUPON_SUCCESS",
    UPDATE_COUPON_FAILURE: "UPDATE_COUPON_FAILURE",

    UPDATE_SERVICE_AMOUNT_REQUEST: "UPDATE_SERVICE_AMOUNT_REQUEST",
    UPDATE_SERVICE_AMOUNT_SUCCESS: "UPDATE_SERVICE_AMOUNT_SUCCESS",
    UPDATE_SERVICE_AMOUNT_FAILURE: "UPDATE_SERVICE_AMOUNT_FAILURE",

    PAYMENT_METHOD_SETTING_REQUEST: "PAYMENT_METHOD_SETTING_REQUEST",
    PAYMENT_METHOD_SETTING_SUCCESS: "PAYMENT_METHOD_SETTING_SUCCESS",
    PAYMENT_METHOD_SETTING_FAILURE: "PAYMENT_METHOD_SETTING_FAILURE",

    ACTIVE_USER_REQUEST: "ACTIVE_USER_REQUEST",
    ACTIVE_USER_SUCCESS: "ACTIVE_USER_SUCCESS",
    ACTIVE_USER_FAILURE: "ACTIVE_USER_FAILURE",


    LOCKALKING_PAYOUT_LIST_REQUEST: "LOCKALKING_PAYOUT_LIST_REQUEST",
    LOCKALKING_PAYOUT_LIST_SUCCESS: "LOCKALKING_PAYOUT_LIST_SUCCESS",
    LOCKALKING_PAYOUT_LIST_FAILURE: "LOCKALKING_PAYOUT_LIST_FAILURE",


    CREATE_WALK_IN_SCREEN_REQUEST: "CREATE_WALK_IN_SCREEN_REQUEST",
    CREATE_WALK_IN_SCREEN_SUCCESS: "CREATE_WALK_IN_SCREEN_SUCCESS",
    CREATE_WALK_IN_SCREEN_FAILURE: "CREATE_WALK_IN_SCREEN_FAILURE",

    FETC_WALK_IN_SCREEN_REQUEST: "FETC_WALK_IN_SCREEN_REQUEST",
    FETC_WALK_IN_SCREEN_SUCCESS: "FETC_WALK_IN_SCREEN_SUCCESS",
    FETC_WALK_IN_SCREEN_FAILURE: "FETC_WALK_IN_SCREEN_FAILURE",

    DELETE_WALK_IN_SCREEN_REQUEST: "DELETE_WALK_IN_SCREEN_REQUEST",
    DELETE_WALK_IN_SCREEN_SUCCESS: "DELETE_WALK_IN_SCREEN_SUCCESS",
    DELETE_WALK_IN_SCREEN_FAILURE: "DELETE_WALK_IN_SCREEN_FAILURE",

    FETCH_SINGLE_WALK_IN_SCREEN_REQUEST: "FETCH_SINGLE_WALK_IN_SCREEN_REQUEST",
    FETCH_SINGLE_WALK_IN_SCREEN_SUCCESS: "FETCH_SINGLE_WALK_IN_SCREEN_SUCCESS",
    FETCH_SINGLE_WALK_IN_SCREEN_FAILURE: "FETCH_SINGLE_WALK_IN_SCREEN_FAILURE",

    UPDATE_WALK_IN_SCREEN_REQUEST: "UPDATE_WALK_IN_SCREEN_REQUEST",
    UPDATE_WALK_IN_SCREEN_SUCCESS: "UPDATE_WALK_IN_SCREEN_SUCCESS",
    UPDATE_WALK_IN_SCREEN_FAILURE: "UPDATE_WALK_IN_SCREEN_FAILURE",

    GET_UNREAD_NOTIFICATION_REQUEST: "GET_UNREAD_NOTIFICATION_REQUEST",
    GET_UNREAD_NOTIFICATION_SUCCESS: "GET_UNREAD_NOTIFICATION_SUCCESS",
    GET_UNREAD_NOTIFICATION_FAILURE: "GET_UNREAD_NOTIFICATION_FAILURE",

    GET_READ_NOTIFICATION_REQUEST: "GET_READ_NOTIFICATION_REQUEST",
    GET_READ_NOTIFICATION_SUCCESS: "GET_READ_NOTIFICATION_SUCCESS",
    GET_READ_NOTIFICATION_FAILURE: "GET_READ_NOTIFICATION_FAILURE",

    READ_NOTIFICATION_REQUEST: "READ_NOTIFICATION_REQUEST",
    READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
    READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",

    GET_SERVICES_AMOUNT_REQUEST: "GET_SERVICES_AMOUNT_REQUEST",
    GET_SERVICES_AMOUNT_SUCCESS: "GET_SERVICES_AMOUNT_SUCCESS",
    GET_SERVICES_AMOUNT_FAILURE: "GET_SERVICES_AMOUNT_FAILURE",


    GET_CHAT_DOWNLOAD_REQUEST: "GET_CHAT_DOWNLOAD_REQUEST",
    GET_CHAT_DOWNLOAD_SUCCESS: "GET_CHAT_DOWNLOAD_SUCCESS",
    GET_CHAT_DOWNLOAD_FAILUER: "GET_CHAT_DOWNLOAD_FAILUER",

};

export default userActionTypes;
