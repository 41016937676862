import React, { useEffect, useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import Imageselector from "../custom/imageSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMe, updateMe } from "../../redux/auth/action";
import { Grid } from "@mui/material";
import NavBar from "../custom/navBar";

const useStyles = makeStyles({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    formGroup: {
        display: "flex",
        flexDirection: "column",
    },
    formControl: {
        border: "1px solid #E6EAF0 !important",
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
    },
    selector: {
        width: "355px",
        height: "30px",
        border: "1px solid #E6EAF0",
        borderRadius: "5px",
    },

    editButton: {
        background: "white",
        border: "none",
        color: "blue",
        fontWeight: "bold",
        fontSize: "16px",
        cursor: "pointer",
    },
    button: {
        background: "rgb(0, 58, 210)",
        width: "204px",
        border: "none",
        borderRadius: "5px",
        color: "white",
    },
});

export default function AddProfileForm() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const currentUser = useSelector(({ getMeReducer }) => getMeReducer);
    const [getInputField, setInputField] = useState({})

    useEffect(() => {
        dispatch(getMe());
    }, []);

    useEffect(() => {
        setInputField({
            image: currentUser?.currentUser.data?.image,
            currentRole: 'Admin'
        });
    }, [currentUser]);
    const InputsObj = [
        {
            id: 1,
            label: "First Name",
            palceHolder: "First Name",
            type: "text",
            name: "firstName",
            value: currentUser?.currentUser?.data?.firstName,
            disabled: "disabled",
        },
        {
            id: 2,
            label: "Last Name",
            palceHolder: "Last Name",
            type: "text",
            name: "lastName",
            value: currentUser?.currentUser?.data?.lastName,
            disabled: "disabled",
        },
        {
            id: 3,
            label: "Email",
            palceHolder: "Email Address",
            type: "email",
            name: "email",
            value: currentUser?.currentUser?.data?.email,
            disabled: "disabled",
        },
        {
            id: 4,
            label: "Mobile",
            palceHolder: "Mobile Number",
            type: "text",
            name: "mobile",
            value: currentUser?.currentUser?.data?.phoneCode  + " "  + currentUser?.currentUser?.data?.mobile ?? " ",
            disabled: "disabled",
        },
    ];

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target?.files?.length > 0) {
            var file = e.target.files["0"];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputField({ ...getInputField, [name]: reader.result });
                dispatch(updateMe({image:reader.result, currentRole:'Admin'}));
            };
            reader.readAsDataURL(file);
        }
    };

    const location = useLocation();
    return (
        <>
            {currentUser.loading ? (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "135vh",
                        width: "72%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <CircularProgress />
                </Grid>
            ) :
                <>
                    <NavBar title="Settings" url={location.pathname} />
                    <div className="formBox">

                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="mb-4 font-29 font-weight-700" style={{ color: "#1F4173", }}>
                                    Profile
                                </h5>
                            </div>
                            <div className="col-md-6 text-end">
                                <CustomButton
                                    onClick={() => {
                                        setDisabled(!disabled);
                                        navigate("/Settings/profile/edit-profile");
                                    }}
                                    className={classes.editButton}
                                >
                                    Edit
                                </CustomButton>
                            </div>
                        </div>

                        <div className="row mb-5 mt-4">
                            <div className="co-md-12 text-center">
                                {/* <Imageselector imgUrl={process.env.REACT_APP_UPLOAD_URL + currentUser?.currentUser?.data?.image} disabled={disabled} /> */}
                                <Imageselector imgUrl={getInputField?.image?.includes("base64") ? getInputField?.image : process.env.REACT_APP_UPLOAD_URL + getInputField?.image} handleChangeImage={handleChange} />
                            </div>
                        </div>


                        <div className="row pt-5">
                            {InputsObj.map((val) => {
                                return (
                                    <div className="col-md-6 col-sm-12" key={val.id}>
                                        <div className="mb-5 customProfileInput">
                                            <TextField
                                                disabled
                                                id={val.id}
                                                type={val.type}
                                                label={val.label}
                                                defaultValue={val.value}
                                                name={val.name}
                                                sx={{ width: "100%" }}
                                            />

                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            }
        </>
    );
}
