import userActionTypes from "./action.types";

const initialState = {
    items: [],
    payoutList: [],
    loading: false,
    error: null,
    active: 1
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CUSTOMER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "CUSTOMER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "CUSTOMER_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const customerFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CUSTOMER_FILTER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "CUSTOMER_FILTER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "CUSTOMER_FILTER_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const OrderReviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_REVIEW_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ORDER_REVIEW_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "ORDER_REVIEW_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const OrderReviewDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_REVIEW_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ORDER_REVIEW_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "ORDER_REVIEW_DELETE_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const EditUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EDIT_USER_REQUEST ":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "EDIT_USER_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "EDIT_USER_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const singleUserDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SINGLE_USER_REQUEST ":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "SINGLE_USER_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "SINGLE_USER_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const singleCouponDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SINGLE_COUPON_REQUEST ":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "SINGLE_COUPON_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "SINGLE_COUPON_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};



export const localkingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOCALKING_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "LOCALKING_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "LOCALKING_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const LocalKingFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOCALKING_FILTER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "LOCALKING_FILTER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "LocalKing_FILTER_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ORDER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "ORDER_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const OrderFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ORDER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "ORDER_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const recentFiveOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_FIVE_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ORDER_FIVE_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "ORDER_FIVE_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const payoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.PAYOUT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case userActionTypes.PAYOUT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case userActionTypes.PAYOUT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const cancelOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_CANCEL_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ORDER_CANCEL_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "ORDER_CANCEL_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SERVICE_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "GET_SERVICE_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "GET_SERVICE_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const schedulePayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SCHEDULE_PAYOUT_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "SCHEDULE_PAYOUT_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "SCHEDULE_PAYOUT_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const getSchedulePayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SCHEDULE_PAYOUT_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "GET_SCHEDULE_PAYOUT_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "GET_SCHEDULE_PAYOUT_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};
 
export const createCouponReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.COUPON_CREATED_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case userActionTypes.COUPON_CREATED_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case userActionTypes.COUPON_CREATED_FAILURE:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const getCouponListReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.COUPON_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case userActionTypes.COUPON_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case userActionTypes.COUPON_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const couponDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DELETE_COUPON_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "DELETE_COUPON_SUCCESS":
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            };
        case "DELETE_COUPON_FAILURE":
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const getSingleCouponDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SINGLE_COUPON_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "SINGLE_COUPON_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "SINGLE_COUPON_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const updateCouponReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_COUPON_REQUEST ":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UPDATE_COUPON_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "UPDATE_COUPON_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const updateServiceAmountReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_SERVICE_AMOUNT_REQUEST ":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UPDATE_SERVICE_AMOUNT_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "UPDATE_SERVICE_AMOUNT_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const fetchLocalKingPaymentListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOCKALKING_PAYOUT_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "LOCKALKING_PAYOUT_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "LOCKALKING_PAYOUT_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                payoutList: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const paymentmethodSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PAYMENT_METHOD_SETTING_REQUEST ":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "PAYMENT_METHOD_SETTING_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "PAYMENT_METHOD_SETTING_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const createWalkinScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CREATE_WALK_IN_SCREEN_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "CREATE_WALK_IN_SCREEN_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "CREATE_WALK_IN_SCREEN_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const fetchWalkInScreensReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETC_WALK_IN_SCREEN_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "FETC_WALK_IN_SCREEN_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "FETC_WALK_IN_SCREEN_FAILURE":
            return {
                ...state,
                loading: false,
                payoutList: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const deleteWalkInScreensReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DELETE_WALK_IN_SCREEN_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "DELETE_WALK_IN_SCREEN_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "DELETE_WALK_IN_SCREEN_FAILURE":
            return {
                ...state,
                loading: false,
                payoutList: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const fetchSingleWalkInScreenDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_SINGLE_WALK_IN_SCREEN_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "FETCH_SINGLE_WALK_IN_SCREEN_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "FETCH_SINGLE_WALK_IN_SCREEN_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};


export const updateWalkInScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_WALK_IN_SCREEN_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UPDATE_WALK_IN_SCREEN_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "UPDATE_WALK_IN_SCREEN_FAILURE":
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const getReadNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_READ_NOTIFICATION_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "GET_READ_NOTIFICATION_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "GET_READ_NOTIFICATION_FAILURE":
            return {
                ...state,
                loading: false,
                payoutList: [],
                error: action.error,
            };
        default:
            return state;
    }

};


export const getUnreadNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_UNREAD_NOTIFICATION_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "GET_UNREAD_NOTIFICATION_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "GET_UNREAD_NOTIFICATION_FAILURE":
            return {
                ...state,
                loading: false,
                payoutList: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const fetchLocalKingForMapListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOCALKING_LIST_FOR_MAP_LIST_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "LOCALKING_LIST_FOR_MAP_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case "LOCALKING_LIST_FOR_MAP_LIST_FAILURE":
            return {
                ...state,
                loading: false,
                payoutList: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export const getServicesAmountReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.GET_SERVICES_AMOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case userActionTypes.GET_SERVICES_AMOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case userActionTypes.GET_SERVICES_AMOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.error,
            };
        default:
            return state;
    }
};
