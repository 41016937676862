import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createPayouts, fetchCustomer, fetchLocalkings } from "../../redux/user/action";
import { useNavigate } from "react-router";

const useStyles = makeStyles({
    Cancelled: {
        background: "red !important",
    },
    Completed: {
        background: "green !important",
    },
    Inprogress: {
        background: "yellow !important",
    },
    customButton: {
        width: "110px",
        height: "26px",
        letterSpacing: "0.5px",
        lineHeight: "50px",
        background: ({ color }) =>
            color === "Completed"
                ? "#29CC97"
                : color === "Inprogress"
                    ? "#FEC400"
                    : color === "Cancelled"
                        ? "red"
                        : color === "pay now"
                            ? "blue"
                            : color === "complete"
                                ? "#29CC97"
                                : "inherit",
        borderRadius: "100px",
        fontSize: "11px",
        fontWeight: 400,
        color: "white",
        letterSpacing: "0.5px",
        textTransform: "uppercase",
        border: "none",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    add: {
        width: "390px",
        height: "55px",
        borderRadius: "8px",
    },
    edit: {
        width: "290px",
        height: "55px",
        borderRadius: "8px",
    },
});

export default function CustomButton({
    children,
    differentColor,
    item,
    payment,
    payNow,
    data,
    localkings,
    bothEmail,
    isdisabled,
    ...otherProps
}) {
    const classes = useStyles({ color: item });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/payout-list/pay-now",{
            state: data
        })
    };
    const handleClickpay = () => {
        dispatch(createPayouts({ localKing: localkings }));
        navigate("/payout-list")
    };


    return (
        <>
            {
                data?.requestedPayoutAmout !== 0 ||
                    data?.payoutStatus == "complete" ? (
                    <button
                        onClick={payment ? handleClick : payNow ? handleClickpay : null}
                        disabled={isdisabled}
                        className={`${differentColor ? classes.add : "dissabled"} ${classes.customButton
                            }`}
                        {...otherProps}
                    >
                        {children}
                    </button>
                ) : (
                    <p>Not Applicable</p>
                )}
        </>
    );
}
