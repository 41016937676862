
import EditProfileForm from "../components/forms/editProfile";
import Layout from "../layout";

export default function EditProfile() {
    return (
        <Layout>
            <EditProfileForm />
        </Layout>
    )
}
