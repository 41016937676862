import React, { useEffect, useState } from "react";
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getSchedulePayoutData, schedulePayout } from "../../redux/user/action";
import NavBar from "../custom/navBar";
const useStyles = makeStyles({
    formGroup: {
        display: "flex",
        flexDirection: "column",
    },
    formControl: {
        border: "1px solid #E6EAF0 !important",
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
    },
    selector: {
        width: "355px",
        height: "30px",
        border: "1px solid #E6EAF0",
        borderRadius: "5px",
    },
    label: {
        height: "100%",
        maxWidth: "100%",
        marginTop: "1em",
        color: "#000000"
        // width: "calc(100% - 330px)",
        // marginLeft: "330px",
    },
});

export default function Payoutsetting() {
    const data = useSelector(
        ({ schedulePayoutReducer }) => schedulePayoutReducer?.items?.data
    );
    const previousData = useSelector(
        ({ getSchedulePayoutReducer }) =>
            getSchedulePayoutReducer?.items?.schedulePayoutData
    );

    

    const [getInputField, setInputField] = useState({});
    useEffect(() => {
        setInputField({
            minPayout: previousData ? previousData?.minPayout : "",
            // payoutDate: previousData ? previousData?.payoutDate : "",
        });
    }, [previousData]);

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getSchedulePayoutData());
    }, [dispatch]);
    const InputsObj = [
        {
            id: "1",
            label: "Min. Payout",
            palceHolder: "00.00",
            type: "number",
            name: "minPayout",
            value: getInputField.minPayout,
        },
        // {
        //   id: "2",
        //   label: "Payout Date",
        //   type: "date",
        //   name: "payoutDate",
        //   value: getInputField.payoutDate,
        // },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...getInputField, [name]: Number(value).toString() });
    };

    const handleclick = (e) => {
        e.preventDefault();

        if(getInputField.minPayout < 1){
            alert('value must be greater than or equal to 1')
            return
        }

        // if (getInputField.payoutDate == "") {
        //     getInputField.payoutDate = data?.payoutDate;
        // }

        // if (getInputField.minPayout == "") {
        //     getInputField.minPayout = data?.minPayout;
        // }
        
        dispatch(schedulePayout(getInputField));
        setTimeout(() => {
            navigate("/payout-list")
        }, 200);
    };

    const location = useLocation();

    return (
        <>

            <NavBar title="Payouts and Payout Methods" url={location.pathname} />
            <div className="formBox">
                <form onSubmit={handleclick}>
                    <h2 className="mb-5 font-weight-700 font-29" style={{ color: "#1F4173" }}>  Payout Setting </h2>
                    <div className="col-lg-12 col-sm-12"
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        {InputsObj.map((val) => {
                            return (
                                <div className="col-md-12 col-lg-6 col-sm-12" key={val.id}>
                                    <div className="mb-4">
                                        <InputField
                                            id={val.id}
                                            label={val.label}
                                            type={val.type}
                                            defaultValue={val.value}
                                            value={val.value}
                                            placeholder={val.palceHolder}
                                            option={val.option}
                                            handleChange={handleChange}
                                            name={val.name}
                                            imageUrl={getInputField.photo}
                                            classStyle={classes}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row mt-5" style={{display:'flex',alignItems:'center'}}>

                        <div className="col-md-5 col-lg-4 col-sm-6">
                            <CustomButton
                                className={`btn font-weight-700 pb-2 pt-2 text-white w-100`}
                                type="submit"
                                style={{
                                    background: "rgb(0, 58, 210)",
                                    with: "300px",
                                    borderRadius: "8px",
                                    fontSize: "20px",
                                }}>
                                Save
                            </CustomButton>
                        </div>

                        <div className="col-md-4 col-lg-2 col-sm-6">
                            <CustomButton
                                style={{
                                    color: "#1F4173",
                                    width: "140px !important ",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                }}
                                onClick={() => navigate("/")}>
                                Cancel
                            </CustomButton>
                        </div>

                    </div>
                </form>
            </div>
        </>
    );
}
