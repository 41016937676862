import { makeStyles, Box, Grid } from "@material-ui/core"; 
import Notification from "../components/notifucation/Notification.jsx";
const useStyles = makeStyles((theme) => ({
    
    rightComponent: {
        height: "100%",
        padding: "20px",
        maxWidth: "100%",
        marginTop: "1em",
        width: "calc(100% - 363px)",
        marginLeft: "345px",
    },
}));

const Layout = ({ children }) => {
    const classname = useStyles();
    return (
        <Grid container>
            <div className="notification_icon">
                <Notification />
            </div>
            <Box className={classname.rightComponent}>
            {children}
            
            </Box>
        </Grid>
    );
};

export default Layout;
