import { makeStyles } from "@material-ui/core";
import CustomButton from "./../custom/Button";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import Selector from "../custom/selector";
import localkings_search from "../../assets/images/Search.png";
import ExportIcon from "../../assets/images/export.png";


const useStyles = makeStyles({
    tableHeader: {
        height: "55px",
        display: "flex",
        padding: "10px",
        justifyContent: "space-between",
        marginBottom: 0,
        paddingBottom: 0,
    },
    title: {
        fontWeight: 700,
        color: "#1F4173",
        fontSize: "29px",
        lineHeight: "45px",
    },
    searchIcon: {
        top: "15px",
        float: "right",
        position: "relative",
    },
    searchContainer: {
        width: "290px",
        margin: "0 40px",
    },
    searchbar: {
        margin: "0 auto",
        width: "100%",
        height: "38px",
        fontSize: "15px",
        border: "none",
        borderBottom: "1px solid #E6EAF0",
        outline: "none",
    },
    add: {
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: "11px",
        color: "#fff",
        background: "blue",
        width: "100px",
        height: "39px",
        borderRadius: "10px",
        outline: "none",
        border: "none",
    },
    export: {
        width: "120px",
        height: "40px",
        background: "rgb(0, 0, 255)",
        borderRadius: "8px",
        color: "white",
        border: "none",
        alignItems: "center",
        "& a:hover": {
            color: "white",
        },
    },


    anchor: {
        color: "white",
        textDecoration: "none",
        fontWeight: "900",
    },
    icon: {
        fontSize: "13px",
        fontWeight: "bold",
        textAlign: "center",
    },
});


export default function TableHeader({ title, extra, data, type, passData, setfilterVal, NavigateLocation,search }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const handleChange = (e) => {
        passData(e.target.value);
    };


    return (
        <>
            <div className="pe-3 ps-3">
                <div className="container-fluid">
                    <div className="row pt-3 d-flex justify-content-between">

                        <div className="col-sm-12 col-md-5 col-lg-5 mb-4">
                            <span className={`mb-0 ${classes.title}`}>{title}</span>
                        </div>

                        <div className="col-sm-12 col-md-4 col-lg-5 mb-4">
                            {extra === "export" ? (
                                null // <Selector setfilterVal={setfilterVal} />
                            ) : (
                                <form
                                    className={`custom_search_form m-0 w-100 position-relative ${classes.searchContainer}`}
                                >
                                    {/* {type !== "transaction" && ( */}

                                    {search == false ? "" :<>
                                        <input
                                            className={`position-absolute search_input ${classes.searchbar}`}
                                            type="text"
                                            placeholder="Search"
                                            onChange={handleChange}
                                        // onKeyPress={handleChange}
                                        />
                                        <img
                                            className={classes.searchIcon}
                                            src={localkings_search}
                                            alt=""
                                        ></img>
                                    </>}
                                    {/* )} */}
                                </form>
                            )}
                        </div>

                        {type == "coupon-list" ? null : type == "localkingList" ? null : (
                            extra === "add" ? (
                                <div className="col-sm-12 col-md-3 col-lg-2 text-end mb-4">
                                    {NavigateLocation == "add-customer" ?
                                    <CustomButton
                                        onClick={() => navigate("/users/List-of-Customer/add-customer")}
                                        className={`font-20 pe-3 ps-3 font-weight-700 ${classes.add}`}
                                    >
                                        Add
                                    </CustomButton>:
                                    <CustomButton
                                        onClick={() => NavigateLocation}
                                        className={`font-20 pe-3 ps-3 font-weight-700 ${classes.add}`}
                                    >
                                        Add
                                    </CustomButton>
                                    }
                                    

                                    
                                </div>
                            ) : extra === "export" ? (
                                <div className="col-sm-12 col-md-3 col-lg-2 text-end mb-4">
                                    <CustomButton className={`${classes.export} `}>
                                        <CSVLink className={classes.anchor} data={data}>
                                            <span className="me-2"><img src={ExportIcon} alt="Export button" /></span>  
                                            <span>Export</span>
                                        </CSVLink>
                                    </CustomButton>
                                </div>
                            ) : extra === "filter" ? (
                                <div className="col-sm-12 col-md-3 col-lg-2 text-end mb-4">
                                    <CustomButton className={classes.search}>Search</CustomButton>
                                </div>
                            ) : extra === "select" ? (
                                <div className="col-sm-12 col-md-3 col-lg-2 text-end mb-4">
                                    <div className="custom_filter">
                                        <Selector setfilterVal={setfilterVal} />
                                    </div>
                                </div>
                            ) : null


                        )}
                    </div>
                </div>

            </div>
        </>
    );
}
