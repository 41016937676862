import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from "./Input";
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmail, updateMobile } from '../../redux/auth/action';
import ModelCloseIcon from "../../assets/images/ModelCloseIcon.png";

const useStyles = makeStyles({
    formControl: {
        border: "1px solid #E6EAF0 !important",
        height: "60px",
        borderRadius: "5px",
        padding: "0 18px",
    },
    label: {
        fontWeight: 500,
        fontSize: "18px",
    },
    button: {
        backgroundColor: "#003AD2 !important",
        background: "#003AD2",
        borderRadius: "8px !important",
        height: "60px",
        width: "100%",
        border: "none !important",
        color: "white !important",
        marginTop: "50px !important",
        textTransform: "capitalize !important",
    },
    ModelCloseIcon: {
        position: "absolute",
        right: "40px",
        top: "30px",
        "& img": {
            cursor: "pointer",
        }
    }
});

export default function OTPVerificationDialog({ title, buttonTitle, className, errorMessage, openModelTrueFalse }) {
    const [getInputField, setInputField] = React.useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openDailog, setOpen] = React.useState(openModelTrueFalse);
    const emailReducer = useSelector(state => state.emailReducer);
    const number = useSelector(({ mobileReducer }) => mobileReducer);

    React.useEffect(() => {
        if (emailReducer?.isChangeEmail === true) {
            setOpen(false)
        }
        if (number.isChangeMobile === true) {
            setOpen(false)
        }
    }, [emailReducer, number])

    const InputsObj = [
        {
            id: 1,
            label: "OTP",
            palceHolder: "Enter Otp",
            type: "number",
            name: "opt",
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            newMobile: localStorage.getItem("email"),
            oldMobile: localStorage.getItem("oldEmail"),
            otp: getInputField,
        };
        dispatch(localStorage.getItem("type") == "email" ? updateEmail(data) : updateMobile(data));
    };

    const [getopenDailogAfterClickCloseBtn, setDailogAfterClickCloseBtn] = React.useState(true);
    const handleClose = () => {
        localStorage.setItem("email", '')
        localStorage.setItem("oldEmail", '')
        localStorage.setItem("type", '')
        window.location.reload();
        setDailogAfterClickCloseBtn(false);
    };

    return (
        <div>
            <Dialog open={getopenDailogAfterClickCloseBtn === false ? getopenDailogAfterClickCloseBtn : openDailog} className={className}>
                <div onClick={handleClose} className={classes.ModelCloseIcon}> <img src={ModelCloseIcon} alt="" /> </div>
                <DialogTitle className='p-0 text-center font-weight-600 font-30 mb-4 mt-3'>{title}</DialogTitle>
                <DialogContent className='p-0'>
                    <DialogContentText className='text-center mb-5 text-dark font-19' style={{ lineheight: 1.7 }}>
                        Please enter the otp sent to your registered
                        {localStorage.getItem("type") == "email" ? " email address" : " mobile number"}
                        <span className='font-weight-700'> {localStorage.getItem("type") == "phone" ? "+91 " : null}
                            {localStorage.getItem("email")}</span> for verification.
                    </DialogContentText>
                    {InputsObj.map((val) => {

                        return (
                            <div keys={val.id}>
                                <InputField
                                    id={val.id}
                                    label={val.label}
                                    type={val.type}
                                    placeholder={val.palceHolder}
                                    option={val.option}
                                    handleChange={handleChange}
                                    name={val.name}
                                    classStyle={classes}
                                />
                            </div>
                        );
                    })}
                </DialogContent>
                {errorMessage !== undefined ?
                    <div className="col-md-12 mt-2">
                        <span className="font-15 font-weight-500 d-block" style={{ color: " #AF2828", }}>
                            {errorMessage}
                        </span>
                    </div>
                    : null
                }
                <DialogActions className='p-0 mb-4'>
                    <Button onClick={handleSubmit} className={`${classes.button} font-weight-700 font-26`}>{buttonTitle}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}