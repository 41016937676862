import { TableCell, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CustomButton from "../custom/Button";
import VertMenu from "../custom/Menu";
import { useDispatch } from "react-redux";
import { activeUser } from "../../redux/user/action";
import { formatDateAndTime, tConvert } from "../custom/formatDateAndTime";
import swal from "sweetalert";

export default function Body({ row, itemIndex, type, currentPage }) {
    const [response, setresponse] = useState();
    const [isActive, setDeactive] = useState(1);

    useEffect(() => {
        setresponse(itemIndex);
    }, [row, row.isActive]);

    const location = useLocation();
    delete row["location"];
    delete row["favourite"];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dashboardObj = {};
    dashboardObj.slNo = response <= 9 ? `0${response}` : response;
    dashboardObj.customer = row?.user?.firstName;
    dashboardObj.localKing = row?.localKing?.firstName;
    dashboardObj.service = row?.service;
    dashboardObj.subService =
        row?.orderStatus === "Confirmed" || row?.orderStatus === "Review" || row?.orderStatus === "Recieved"
        ? "Completed" 
        : row?.orderStatus === "Cancelled" || row?.orderStatus === "Rejected"
            ? "Cancelled"
            : "Inprogress";

    const handelActiveDeactive = async () => {
        const dataTrueFalse = row?.isActive === true ? { id: row?.id, active: 2 } : { id: row?.id, active: 1 }
        var form = document.createElement("div");
        form.innerHTML = `<p className="text-black font-25">Are you sure you want to  <span>${row?.isActive === true ? "Deactivate" : "Activate"
            } ${row?.firstName}</span>?</p>`;
        swal({
            content: form,
            className: "custom_delete_sweet_alert",
            buttons: {
                confirm: {
                    text: "Yes",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                cancel: {
                    text: "No",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then(async function (isConfirm) {
            if (isConfirm === true) {
                const responseData = await dispatch(activeUser(dataTrueFalse, currentPage))
                if (responseData.status === 200) {
                    swal(responseData.data.message, {
                        icon: "success",
                        timer: 2000,
                        button: false,
                    });
                }
            }
        });
    };

    const customerObj = {};
    customerObj.slNo = response <= 9 ? `0${response}` : response;
    customerObj.fname = row.firstName;
    customerObj.lname = row.lastName;
    customerObj.email = row.email;
    customerObj.mobile = row.phoneCode ? row?.phoneCode + " " + row?.mobile : row?.mobile;
    customerObj.rating = row.ratingsAverage;
    customerObj.role = row?.role?.length > 1 ? "yes" : "no";
    customerObj.status = (
        <Button
            style={{ width: "110px", height: "26px", fontSize: "12px", borderRadius: "100px", background: row.isActive === true ? "#29CC97" : "#F12B2C", boxShadow: "none", lineHeight: "26px" }}
            variant="contained"
            component="label"
            defaultValue={row.isActive}
            onClick={handelActiveDeactive}
        >
            {row.isActive === true ? "Active" : "Deactive"}
        </Button>
    );
    customerObj.vert = "vert";

    const localkingObj = {};
    localkingObj.slNo = response <= 9 ? `0${response}` : response;
    localkingObj.firstName = row.firstName;
    localkingObj.lastName = row.lastName;
    localkingObj.email = row.email;
    localkingObj.mobile = row.mobile;
    localkingObj.rating = row.ratingsAverage;
    localkingObj.role = row?.role?.length > 1 ? "YES" : "NO";
    // localkingObj.vert = "vert";

    const paymentObj = {};
    paymentObj.slNo = response <= 9 ? `0${response}` : response;
    // paymentObj.id = row?.id;
    paymentObj.localking = row?.firstName;
    // paymentObj.amount = row?.requestedPayoutAmout / 100 + "€";
    paymentObj.amount = row?.requestedPayoutAmout + "€";
    paymentObj.status = row.payoutStatus || "pay now";

    const InsertValueInTransactionTableNameColumn = (date, time) => {
        return (
            <>
                <p className="mb-0">{date}</p>
                <p className="font-12 text-rock-blue-color font-weight-600">{time}</p>
            </>
        );
    };

    const transactionObj = {};
    transactionObj.slNo = response <= 9 ? `0${response}` : response;
    transactionObj.customer = row?.user?.firstName;
    transactionObj.localking = row?.localKing?.firstName;
    transactionObj.startDate =
        row?.service === "Delivery"
            ? row?.from ? formatDateAndTime(row?.from, "date") : ""
            : row?.from ? InsertValueInTransactionTableNameColumn(
                formatDateAndTime(row?.date, "date"),
                tConvert(row?.from ?? "")
            ) : ""
    transactionObj.endDate =
        row?.service === "Delivery"
            ? row?.to ?  formatDateAndTime(row?.to, "date") : ""
            : row?.to ? InsertValueInTransactionTableNameColumn(
                formatDateAndTime(row?.date, "date"),
                tConvert(row?.to ?? "")
            ) : ""

    transactionObj.package = row?.quantity <= 9 ? `0${row?.quantity}` : row?.quantity;
    transactionObj.service = row?.service;
    transactionObj.subService = row?.subService;
    transactionObj.status = row?.orderStatus === "Confirmed" || row?.orderStatus === "Review" || row?.orderStatus === "Recieved"
    ? "Completed" 
    : row?.orderStatus === "Cancelled" || row?.orderStatus === "Rejected"
        ? "Cancelled"
        : "Inprogress";
    transactionObj.icon = "icon";


    const InsertValueInCouponTableNameColumn = (amountType, name) => {
        if (amountType == 1) {
            return (
                <>
                    <p className="mb-0">{name}</p>
                    <p className="font-12 text-rock-blue-color font-weight-600">Fixed</p>
                </>
            );
        } if (amountType == 2) {
            return (
                <>
                    <p className="mb-0">{name}</p>
                    <p className="font-12 text-rock-blue-color font-weight-600">Percentage</p>
                </>
            );
        }
    };

    const Capitalize = (str)=>{
        return str[0].toUpperCase() + str.slice(1)
    }
    const couponObj = {};
    couponObj.slNo = response <= 9 ? `0${response}` : response;
    couponObj.couponName = InsertValueInCouponTableNameColumn(row?.amountType, row?.couponCode);
    couponObj.service = row?.service;
    couponObj.serviceType = row?.serviceType;
    couponObj.expireDate = formatDateAndTime(row?.expireDate, 'date');
    couponObj.couponAmount = row?.amountType == 1 ? row?.couponAmount + "€" : row?.couponAmount + "%";
    couponObj.couponUses = row?.utilizedCoupons + "/" + row?.couponUses;
    couponObj.vert = "vert";

    const walkInScreenObj = {};
    walkInScreenObj.slNo = response <= 9 ? `0${response}` : response;
    walkInScreenObj.title = row?.titleEnglish;
    walkInScreenObj.subtitle = row?.subtitleEnglish;
    walkInScreenObj.service = row?.descriptionEnglish;
    walkInScreenObj.serviceType = row?.ScreenOrder;
    walkInScreenObj.vert = "vert";


    const notificationObj = {};
    notificationObj.slNo = response <= 9 ? `0${response}` : response;
    // notificationObj.firstName = row?.localKing?.firstName;
    // notificationObj.lastName = row?.localKing?.lastName;
    notificationObj.localKing_description = row?.localKing_description;
    notificationObj.date = formatDateAndTime(row?.time, "date");
    notificationObj.time = formatDateAndTime(row?.time, "time");
    // notificationObj.isRead = row?.isRead === true ? "Read" : "Unread";

    const data = location.pathname.includes("List-of-Customers") ? customerObj : location.pathname.includes("list-of-localkings") ? localkingObj
        : location.pathname.includes("payout-list") ? paymentObj : location.pathname.includes("transaction") ? transactionObj
            : location.pathname.includes("coupon-list") ? couponObj : location.pathname.includes("walk-in-screen") ? walkInScreenObj : location.pathname.includes("notifications") ? notificationObj : dashboardObj;

    const payment = location.pathname.includes("payout-list");
    const handleNavigate = () => {
        navigate("/transactions/view-transaction", {
            state: row._id
        });
    };

    return (

        <TableRow
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>

            {Object.values(data).map((item, index) => {

                return (
                    <TableCell className="primary-color" component="th" scope="row" key={index}>

                        {item === "vert" ? (
                            <VertMenu type={type} data={row} currentPage={currentPage} />
                        ) : item === "Completed" ||
                            item === "Cancelled" ||
                            item === "pay now" ||
                            item === "complete" ||
                            item === "Inprogress" ? (
                            <CustomButton
                                // style={{ color: "white", backgroundColor: "green" }}
                                payment={payment}
                                item={item}
                                localkings={row.id}
                                data={row}
                            >
                                {item}
                            </CustomButton>
                        ) : item === "icon" ? (
                            // <a
                            //   className="pe-2 ps-2"
                            //   style={{cursor:"pointer"}}
                            //   href={row?.receiptPdf_url}
                            //   download="invoice.pdf"
                            // >
                            //   {/* <CloudDownload /> */}
                            //   View
                            // </a>
                            data.status == "Completed" ? (
                                <button
                                    className="pe-2 ps-2 pe-auto ms-4 font-weight-600"
                                    style={{
                                        border: "none",
                                        background: "rgb(255, 255, 255)",
                                        color: "#003AD2",
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        textDecorationColor: "#003ad24f"
                                    }}
                                    onClick={handleNavigate}
                                >
                                    View
                                </button>
                            ) : (
                                <button
                                    className="pe-2 ps-2 pe-auto ms-4 font-weight-600"
                                    style={{
                                        border: "none",
                                        background: "rgb(255, 255, 255)",
                                        color: "#003AD2",
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        textDecorationColor: "#003ad24f"
                                    }}
                                >
                                    View
                                </button>
                            )
                        ) : (
                            item
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}
