import EditCustomerform from "../components/forms/editCustomer";
import Layout from "../layout";

export default function EditCustomer() {
  return (
    <Layout>
      <EditCustomerform />
    </Layout>
  );
}
