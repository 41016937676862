
import ChangeNumber from "../components/forms/changeNumber";
import MobileInputWithCountryCode from "../components/custom/MobileInputWithCountryCode";
import Layout from "../layout";

export default function ChangeMobileNumber() {
    return (
        <Layout>
            <ChangeNumber />
            {/* <div className="mt-4 pt-5">
                <MobileInputWithCountryCode />
            </div> */}
        </Layout>
    )
}