

import { makeStyles } from "@material-ui/core";
import { useSelect } from "@mui/base";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createPayouts, fetchLocalkings } from "../../redux/user/action";

const useStyles = makeStyles({
    Cancelled: {
        background: "red !important",
    },
    Completed: {
        background: "green !important",
    },
    Inprogress: {
        background: "yellow !important",
    },
    customButton: {
        minWidth: "100px",
        width: "50%",
        height: "40px",
        letterSpacing: "0.5px",
        lineHeight: "50px",
        background: ({ color }) =>
            color === "Completed"
                ? "#29CC97"
                : color === "Inprogress"
                    ? "#FEC400"
                    : color === "Cancelled"
                        ? "red"
                        : color === "pay now"
                            ? "blue"
                            : color === "complete"
                                ? "#29CC97"
                                : "inherit",
        borderRadius: "100px",
        fontSize: "12px",
        fontWeight: 900,
        color: "white",
        letterSpacing: "0.5px",
        textTransform: "uppercase",
        border: "none",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    add: {
        width: "390px",
        height: "55px",
        borderRadius: "8px",
    },
    edit: {
        width: "290px",
        height: "55px",
        borderRadius: "8px",
    },
});

export default function CustomDefaultButton({
    children,
    differentColor,
    item,
    payment,
    data,
    localkings,
    bothEmail,
    bothNumber,
    bothCode,
    ...otherProps
}) {
    const classes = useStyles({ color: item });
    const dispatch = useDispatch();
    const [condeition, setCondition] = useState();
    const handleClick = () => {
        dispatch(createPayouts({ localKing: localkings }));
    };
    useEffect(() => {
        setCondition(bothEmail);
    }, [bothEmail]);

    return (
        <>
            {bothEmail == "success" || bothNumber == "success" && bothCode == "success"
                ? (
                    <button
                        onClick={payment ? handleClick : undefined}
                        className={`${differentColor ? classes.add : "dissabled"} ${classes.customButton
                            }`}
                        {...otherProps}
                    >
                        {children}
                    </button>
                ) : (
                    <button
                        onClick={payment ? handleClick : undefined}
                        disabled
                        className={`${differentColor ? classes.add : "dissabled"} ${classes.customButton
                            }`}
                        {...otherProps}
                    >
                        {children}
                    </button>
                )}
        </>
    );
}
