import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtpSetinitialStateFalse, sendOtpToChangeEmailOrMobile, updateMobileSetinitialStateFalse } from "../../redux/auth/action";
import CustomDefaultButton from "../custom/DefaultButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import NavBar from "../custom/navBar";
import CustomButton from "../custom/Button";
import OTPVerificationDialog from "../custom/OTPVerificationDialog";
import VerifiedOTPDialog from "../custom/VerifiedOTPDailog";

import { getCountries, getCountryCallingCode } from 'react-phone-number-input'

const useStyles = makeStyles(() => ({

    formControl: {
        border: (props) => {
            switch (props.bothEmail) {
                case "initail":
                    return "1px solid #E6EAF0";
                case "error":
                    return "2px solid red ";
                case "success":
                    return "2px solid green ";
                default:
                    return "1px solid #E6EAF0 ";
            }
        },
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
        width: "100%",

    },

    CountryDaillingCode: {
        border: (props) => {
            switch (props.bothCode) {
                case "initail":
                    return "1px solid #E6EAF0 !important";
                case "error":
                    return "2px solid red !important";
                case "success":
                    return "2px solid green !important";
                default:
                    return "1px solid #E6EAF0 !important";
            }
        },

    },

    formControlDefault: {
        border: "1px solid #E6EAF0 ",
        height: "48px",
        borderRadius: "5px",
        padding: "0rem 1rem",
    },
    label: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#000000",
        marginBottom: "1rem",
        marginTop: "1rem",
    },

    button: {
        background: "rgb(0, 58, 210)",
        width: "204px",
        border: "none",
        borderRadius: "5px",
        color: "white",
    },
}));

export default function ChangeNumber() {
    const dispatch = useDispatch();
    const [bothNumber, setBothNumber] = useState();
    const [bothCode, setBothCode] = useState();
    const classes = useStyles({ bothNumber, bothCode });
    const navigate = useNavigate();

    const [getInputField, setInputField] = useState({
        oldMobile: "",
        mobile: "",
        mobileConfirm: "",

        oldMobileCountryCode: "+91",
        mobileCountryCode: "+91",
        mobileConfirmCountryCode: "+91",
    });

    useEffect(() => {
        dispatch(sendOtpSetinitialStateFalse());
    }, [])

    useEffect(() => {
        dispatch(updateMobileSetinitialStateFalse());
    }, [])

    const forgotPasswordReducer = useSelector(state => state.forgotPasswordReducer);
    const number = useSelector(({ mobileReducer }) => mobileReducer);

    const crntEmail = JSON.parse(localStorage.getItem("persist:root"));
    const getemail = JSON.parse(crntEmail.loginReducer);
    const loginMobile = getemail.currentUser.data.currentUser.mobile;

    const val_1 = getInputField.mobile;
    const val_2 = getInputField.mobileConfirm;

    const checkNewNumberAndConfirmNumberSameOrNot = () => {
        if (val_1 == "" && val_2 == "") {
            setBothNumber("initial");
        } else if (val_2 && val_1 !== val_2) {
            setBothNumber("error");
        } else if (
            val_1 !== "" &&
            val_1 !== "" &&
            val_2.startsWith(val_1) &&
            val_1.startsWith(val_2)
        ) {
            setBothNumber("success");
        }
    };

    const code_1 = getInputField.mobileCountryCode;
    const code_2 = getInputField.mobileConfirmCountryCode;
    const checkNewNumberCodeAndConfirmNumberCodeSameOrNot = () => {
        if (code_1 == "" && code_2 == "") {
            setBothCode("initial");
        } else if (code_2 && code_1 !== code_2) {
            setBothCode("error");
        } else if (
            code_1 !== "" &&
            code_1 !== "" &&
            code_2.startsWith(code_1) &&
            code_1.startsWith(code_2)
        ) {
            setBothCode("success");
        }
    };

    useEffect(() => {
        checkNewNumberAndConfirmNumberSameOrNot();
        checkNewNumberCodeAndConfirmNumberCodeSameOrNot();
    }, [val_1, val_2, code_1, code_2]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...getInputField, [name]: value });
    };

    const handleclick = (e) => {
        e.preventDefault();
        const data = {
            type: "phone",
            oldEmail: getInputField?.oldMobile,
            email: getInputField?.mobile,
            phoneCode: getInputField.mobileCountryCode,
        };
        loginMobile == getInputField.oldMobile ? dispatch(sendOtpToChangeEmailOrMobile(data)) : alert("Current Mobile Number does not exist");
    };


    if (forgotPasswordReducer?.isVerifiedEmail === true) {
        localStorage.setItem("email", getInputField?.mobile)
        localStorage.setItem("oldEmail", getInputField?.oldMobile)
        localStorage.setItem("type", "phone")
    }

    setTimeout(() => {
        if (number.isChangeMobile === true) {
            localStorage.removeItem("persist:root");
            window.location.reload();
            window.localStorage.clear();
        }
    }, 3000);

    const location = useLocation();

    return (
        <>
            {number.loading && (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "70%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <CircularProgress />
                </Grid>
            )}

            {number.isChangeMobile === true ?
                <VerifiedOTPDialog title="OTP Verification" buttonTitle="Verify" className="OTPVerificationDialog" /> : null
            }

            {
                forgotPasswordReducer?.isVerifiedEmail === true ?
                    <OTPVerificationDialog title="OTP Verification" buttonTitle="Verify" className="OTPVerificationDialog" errorMessage={number?.error?.response?.data?.message} openModelTrueFalse={forgotPasswordReducer?.isVerifiedEmail} /> : null
            }

            <NavBar title="Settings" subtitle="Change Mobile Number" url={location.pathname} />
            <div className="formBox">
                <form onSubmit={handleclick}>
                    <h2 className="mb-5 font-29 mt-2 font-weight-700" style={{ color: "#1F4173" }}> Change Mobile Number </h2>

                    <div className="col-lg-12 col-sm-12 pt-3 pb-5">
                        <div className="row">

                            <div className="col-md-12">
                                <div className="mb-4">
                                    <label className={classes.label}>Current Mobile Number</label>
                                    <div className="customMobileInput">
                                        <select className="customCountryCallingCode" name="oldMobileCountryCode" onChange={handleChange} value={getInputField.oldMobileCountryCode}>
                                            {getCountries().map((country) => (
                                                <>
                                                    <option key={country} value={`+${getCountryCallingCode(country)}`}>
                                                        + {getCountryCallingCode(country)}
                                                    </option>
                                                </>
                                            ))}
                                        </select>

                                        <input type="number" name="oldMobile" className={`form-control`} onChange={handleChange} placeholder="Enter current Mobile Number" />

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="mb-4">
                                    <label className={classes.label}>New Mobile Number</label>
                                    <div className="customMobileInput">
                                        <select className={`${classes.CountryDaillingCode} customCountryCallingCode`} name="mobileCountryCode" onChange={handleChange} value={getInputField.mobileCountryCode}>
                                            {getCountries().map((country) => (
                                                <>
                                                    <option key={country} value={`+${getCountryCallingCode(country)}`}>
                                                        + {getCountryCallingCode(country)}
                                                    </option>
                                                </>
                                            ))}
                                        </select>
                                        <input type="number" name="mobile" className={`${classes.formControl}`} onChange={handleChange} placeholder="Enter new Mobile Number" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="mb-4">
                                    <label className={classes.label}>Confirm New Mobile Number</label>
                                    <div className="customMobileInput">
                                        <select className={`${classes.CountryDaillingCode} customCountryCallingCode`} name="mobileConfirmCountryCode" onChange={handleChange} value={getInputField.mobileConfirmCountryCode}>
                                            {getCountries().map((country) => (
                                                <>
                                                    <option key={country} value={`+${getCountryCallingCode(country)}`}>
                                                        + {getCountryCallingCode(country)}
                                                    </option>
                                                </>
                                            ))}
                                        </select>
                                        <input type="number" name="mobileConfirm" className={`${classes.formControl}`} onChange={handleChange} placeholder="Re-enter new Mobile Number" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* {InputsObj.map((val) => {
                            return (
                                <div className="row">
                                
                                    <div className="col-md-12" key={val.id}>
                                        <div className="mb-4">

                                            <InputField
                                                id={val.id}
                                                label={val.label}
                                                type={val.type}
                                                value={val.value}
                                                placeholder={val.palceHolder}
                                                option={val.option}
                                                handleChange={handleChange}
                                                name={val.name}
                                                imageUrl={getInputField.photo}
                                                classStyle={classes}
                                            />

                                        </div>
                                    </div>
                                </div>
                            );
                        })} */}
                        {forgotPasswordReducer.error !== null ?
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="font-15 font-weight-500 d-block" style={{ color: " #AF2828", }}>
                                        {forgotPasswordReducer.error}
                                    </span>
                                </div>
                            </div>
                            : null
                        }
                    </div>


                    <div className="row mt-5 pt-5 mb-5">
                        <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                            <div className="row">
                                <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                    <CustomDefaultButton
                                        className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                        type="submit"
                                        style={{
                                            background: "#003AD2",
                                            borderRadius: "8px",
                                            width: "290px",
                                            height: "55px",
                                            fontSize: "26px"
                                        }}
                                        bothNumber={bothNumber}
                                        bothCode={bothCode}>
                                        Save Changes
                                    </CustomDefaultButton>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                    <CustomButton
                                        style={{
                                            color: "#1F4173",
                                            width: "140px !important ",
                                            textTransform: "capitalize",
                                            height: "55px",
                                            fontWeight: 700,
                                            fontSize: "26px"
                                        }}
                                        onClick={() => navigate("/")}>
                                        Cancel
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    );
}
