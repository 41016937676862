import React, { useEffect, useState } from "react";
import InputField from "../custom/Input";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import CustomButton from "../custom/Button";
import NavBar from "../custom/navBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import {
  fetchSingleWalkInScreenDetails,
  updateWalkInScreen,
} from "../../redux/user/action";
import { IconButton, Tooltip } from "@mui/material";
import WarningIcon from "@material-ui/icons/Warning";
export default function EditWalkInScreenForm({ NameOfuser, role }) {
  const useStyles = makeStyles({
    paddingLeftAndRight: {
      padding: "0px 34px",
      marginBottom: "1.5rem!important",
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
    },
    formControl: {
      border: "1px solid #E6EAF0 !important",
      height: "48px",
      borderRadius: "5px",
      padding: "0rem 1rem",
      width: "100%",
    },
    textarea: {
      padding: "1rem 1rem",
      border: "1px solid #E6EAF0 !important",
      borderRadius: "5px",
      fontSize: "13px",
    },
    imageSelector: {
      width: "100%",
      border: "1px solid #E6EAF0",
      borderRadius: "5px",
    },
    selector: {
      border: "1px solid #E6EAF0 !important",
      height: "48px",
      borderRadius: "5px",
      padding: "0rem 1rem",
      width: "100%",
    },
    label: {
      height: "100%",
      padding: "1 0px",
      maxWidth: "100%",
      marginTop: "1em",
    },
    hr: {
      margin: " 1rem 0",
      color: "inherit",
      backgroundColor: "#E6EAF0",
      border: 0,
      opacity: 0.25,
      height: "3px !important",
    },
    editButton: {
      background: "white",
      border: "none",
      color: "blue",
      fontWeight: "bold",
      fontSize: "16px",
      cursor: "pointer",
    },
  });

  const classes = useStyles();
  const register = useSelector(({ registerReducer }) => registerReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { id } = state;

  const getWalkInScreen = useSelector(
    ({ fetchSingleWalkInScreenDetailsReducer }) =>
      fetchSingleWalkInScreenDetailsReducer
  );
  const walkInScreenData = getWalkInScreen?.items?.data;

  useEffect(() => {
    dispatch(fetchSingleWalkInScreenDetails(id));
  }, [id]);

  const [getWalkInScreenImageField, setWalkInScreenImageField] = useState();
  const [getInputField, setInputField] = useState({});
  useEffect(() => {
    setInputField({
      titleEnglish: walkInScreenData?.titleEnglish,
      descriptionEnglish: walkInScreenData?.descriptionEnglish,
      subtitleEnglish: walkInScreenData?.subtitleEnglish,

      titleGerman: walkInScreenData?.titleGerman,
      descriptionGerman: walkInScreenData?.descriptionGerman,
      subtitleGerman: walkInScreenData?.subtitleGerman,

      ScreenOrder: walkInScreenData?.ScreenOrder,
      image: walkInScreenData?.image,
    });
  }, [getWalkInScreen]);

  const InputsObj = [
    {
      id: 1,
      label: "Title (English)",
      palceHolder: "Enter English title",
      type: "text",
      name: "titleEnglish",
      requied: "requied",
      value:
        getInputField?.titleEnglish === undefined
          ? ""
          : getInputField?.titleEnglish,
    },
    {
      id: 2,
      label: "Title (German)",
      palceHolder: "Enter German title",
      type: "text",
      name: "titleGerman",
      requied: "requied",
      value:
        getInputField?.titleGerman === undefined
          ? ""
          : getInputField?.titleGerman,
    },

    {
      id: 3,
      label: "hr",
      type: "hr",
    },

    {
      id: 4,
      label: "Subtitle (English)",
      palceHolder: "Enter English Subtitle",
      type: "text",
      name: "subtitleEnglish",
      requied: "requied",
      value:
        getInputField?.subtitleEnglish === undefined
          ? ""
          : getInputField?.subtitleEnglish,
    },
    {
      id: 5,
      label: "Subtitle (German)",
      palceHolder: "Enter German Subtitle",
      type: "text",
      name: "subtitleGerman",
      requied: "requied",
      value:
        getInputField?.subtitleGerman === undefined
          ? ""
          : getInputField?.subtitleGerman,
    },

    {
      id: 6,
      label: "hr",
      type: "hr",
    },

    {
      id: 7,
      label: "Description (English)",
      palceHolder: "Enter English description",
      type: "textarea",
      name: "descriptionEnglish",
      requied: "requied",
      value:
        getInputField?.descriptionEnglish === undefined
          ? ""
          : getInputField?.descriptionEnglish,
    },
    {
      id: 8,
      label: "Description (German)",
      palceHolder: "Enter German description",
      type: "textarea",
      name: "descriptionGerman",
      requied: "requied",
      value:
        getInputField?.descriptionGerman === undefined
          ? ""
          : getInputField?.descriptionGerman,
    },

    {
      id: 9,
      label: "hr",
      type: "hr",
    },

    {
      id: 10,
      label: "Screen Order",
      palceHolder: "Enter Screen Order",
      type: "number",
      name: "ScreenOrder",
      requied: "requied",
      value:
        getInputField?.ScreenOrder === undefined
          ? ""
          : getInputField?.ScreenOrder,
      min: 1,
    },

    {
      id: 11,
      imgLabel: "Upload Image",
      palceHolder: "Select Image",
      type: "file",
      name: "image",
      requied: "requied",
      value: getInputField?.image,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputField({ ...getInputField, [name]: value });
    if (e.target.files) {
      const image = e.target?.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        setWalkInScreenImageField({
          ...getWalkInScreenImageField,
          [name]: reader.result,
        });
      };
      reader.readAsDataURL(image);
      setInputField({ ...getInputField, [name]: image });
    }
  };

  let formData = new FormData();

  formData.append("titleEnglish", getInputField.titleEnglish);
  formData.append("descriptionEnglish", getInputField.descriptionEnglish);
  formData.append("subtitleEnglish", getInputField.subtitleEnglish);

  formData.append("titleGerman", getInputField.titleGerman);
  formData.append("descriptionGerman", getInputField.descriptionGerman);
  formData.append("subtitleGerman", getInputField.subtitleGerman);

  formData.append("image", getInputField?.image);
  formData.append("ScreenOrder", getInputField?.ScreenOrder);
  const handleclick = async (e) => {
    e.preventDefault();
    if (getInputField.image.size >= 1000000) {
      alert("File size must be smaller then 1 MB.");
      return null;
    }

    const response = await dispatch(updateWalkInScreen(id, formData));

    if (response.status === 200) {
      navigate("/list-of-walk-in-screens");
    }
  };

  const location = useLocation();
  return (
    <>
      {register.loading && (
        <Grid
          container
          justifyContent="center"
          style={{
            height: "123vh",
            width: "70%",
            position: "absolute",
          }}
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}

      <NavBar title="Walk-in Screens" url={location.pathname} />

      <div className="formBox pe-0 ps-0">
        <div className={classes.paddingLeftAndRight}>
          <div className="row">
            <div className="col-md-6">
              <h2
                className={`mb-4 font-29 font-weight-700`}
                style={{ color: "#1F4173" }}
              >
                Edit Walk-in Screen
              </h2>
            </div>
            <div className="col-md-6 text-end">
              <CustomButton
                onClick={() => {
                  navigate("/list-of-walk-in-screens/");
                }}
                className={classes.editButton}
              >
                Back
              </CustomButton>
            </div>
          </div>
        </div>

        <form onSubmit={handleclick}>
          <div className="row">
            {InputsObj.map((val) => {
              const cl = `col-lg-${val.type === "number" ? 6 : 12} col-sm-12`;
              return (
                <div className={cl} key={val.id}>
                  <div
                    className={`${
                      val.type === "hr" ? "mb-4" : classes.paddingLeftAndRight
                    }`}
                  >
                    <InputField
                      id={val.id}
                      label={val.label}
                      type={val.type}
                      placeholder={val.palceHolder}
                      handleChange={handleChange}
                      name={val.name}
                      imageUrl={getInputField.image}
                      classStyle={classes}
                      value={val.value}
                      requied={val.requied}
                      min={val.min}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Tooltip title="File size must be smaller then 1 MB.">
            <div className={classes.paddingLeftAndRight}>
              <div className="col-lg-12 col-sm-12">
                <label className="form-label" htmlFor="input-field">
                  {InputsObj[10].imgLabel}
                </label>
                <label className={`${classes.imageSelector} pb-5 pt-5`}>
                  <div className="text-center">
                    {getWalkInScreenImageField?.image !== undefined ||
                    getInputField?.image !== null ? (
                      <img
                        style={{
                          boxShadow: " 0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "6px",
                          height: "100%",
                          width: "100%",
                        }}
                        src={
                          getWalkInScreenImageField?.image !== undefined
                            ? getWalkInScreenImageField?.image
                            : process.env.REACT_APP_UPLOAD_URL +
                              getInputField.image
                        }
                        alt="vector"
                      />
                    ) : (
                      <img
                        style={{
                          boxShadow: " 0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "6px",
                        }}
                        src={"/Vector.png"}
                        alt="vector"
                      />
                    )}
                    {getInputField?.image == null ? (
                      <p
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          color: "#8E8E8E",
                        }}
                        className="mt-4 mb-0"
                      >
                        <p className="mb-0">
                          Select/browse an image for walk-in screen
                        </p>
                      </p>
                    ) : null}
                  </div>
                  <input
                    hidden
                    onChange={handleChange}
                    name="image"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </label>
              </div>
            </div>
          </Tooltip>
          <div className={classes.paddingLeftAndRight}>
            <div className="row mt-4">
              <div className="col-md-4 col-lg-3 col-sm-6">
                <CustomButton
                  className={`btn font-weight-700 pb-2 pt-2 text-white w-100`}
                  type="submit"
                  style={{ background: "rgb(0, 58, 210)", with: "300px" }}
                >
                  Save
                </CustomButton>
              </div>
              <div className="col-md-4 col-lg-2 col-sm-6">
                <CustomButton
                  style={{
                    color: "#1F4173",
                    width: "140px !important ",
                    fontWeight: 700,
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate("/list-of-walk-in-screens/")}
                >
                  Cancel
                </CustomButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
