import { useDispatch } from "react-redux";
import Payoutsetting from "../components/forms/payoutsetting";
import Layout from "../layout";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import NavBar from "../components/custom/navBar";
import { useLocation } from "react-router-dom";
import CustomButton from "../components/custom/Button";
import { height, positions } from "@material-ui/system";
import { padding } from "@mui/system";
import { formatDateAndTime } from "../components/custom/formatDateAndTime";

const useStyles = makeStyles({
  component: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "38px",
    color: "#003AD2",
    marginBottom: "2em",
  },
});

export default function PayoutDetails() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const payNow = location.pathname.includes("/pay-now");

  return (
    <Layout>
      <NavBar title="Payouts and Payout Methods" url={location?.pathname} />
      <div
        style={{
          boxShadow: " 0px 4px 90px rgba(163, 171, 185, 0.24)",
          borderRadius: "15px",
        }}
      >
        <div style={{ padding: "40px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: " #1F4173",
            }}
          >
            <h3 className="font-29 mb-3 font-weight-700">Payout details</h3>

            <div style={{ width: "500px", padding: "20px 5px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ color: "#8E8E8E", width: "115px" }}>Name</p>:
                <p
                  style={{
                    width: "180px",
                    paddingLeft: "50px",
                    width: "300px",
                  }}
                >
                  {location?.state.account[0].accountHolderName}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "#8E8E8E", width: "115px" }}>Duration</p>:
                <p
                  style={{
                    width: "180px",
                    paddingLeft: "50px",
                    width: "300px",
                  }}
                >
                  {location?.state?.payAt
                    ? formatDateAndTime(location?.state?.payAt, "date") +
                      " - " +
                      formatDateAndTime(location?.state?.createdAt, "date")
                    : formatDateAndTime(location?.state?.createdAt, "date")}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "#8E8E8E", width: "115px" }}>Total Amount</p>
                :
                <p
                  style={{
                    width: "180px",
                    paddingLeft: "50px",
                    width: "300px",
                  }}
                >
                  {location?.state.requestedPayoutAmout} €
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "#8E8E8E", width: "115px" }}>IBAN</p>:
                <p
                  style={{
                    width: "180px",
                    paddingLeft: "50px",
                    width: "300px",
                  }}
                >
                  {location?.state.account[0].IBAN}
                </p>
              </div>
              <div
                style={{
                  padding: "0px !important",
                  marginTop: "50px",
                }}
              >
                <div className="col-md-5 col-lg-6 col-sm-6">
                  <CustomButton
                    className={`btn font-weight-700 pb-2 pt-2 text-white w-100`}
                    type="submit"
                    style={{
                      background: "rgb(0, 58, 210)",
                      with: "300px",
                      borderRadius: "8px",
                      fontSize: "20px",
                    }}
                    payNow={payNow}
                    localkings={location?.state?._id}
                  >
                    Pay Now
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
