import { combineReducers } from "redux";
import {
    customerReducer,
    customerFilterReducer,
    OrderReviewReducer,
    OrderReviewDeleteReducer,
    localkingReducer,
    LocalKingFilterReducer,
    orderReducer,
    serviceReducer,
    recentFiveOrderReducer,
    EditUserReducer,
    singleUserDetailsReducer,
    cancelOrderReducer,
    OrderFilterReducer,
    schedulePayoutReducer,
    getSchedulePayoutReducer,
    getCouponListReducer,
    getSingleCouponDetailsReducer,
    updateCouponReducer,
    createCouponReducer,
    updateServiceAmountReducer,
    fetchLocalKingPaymentListReducer,
    paymentmethodSettingReducer,
    createWalkinScreenReducer,
    fetchWalkInScreensReducer,
    deleteWalkInScreensReducer,
    fetchSingleWalkInScreenDetailsReducer,
    updateWalkInScreenReducer,
    getReadNotificationReducer,
    getUnreadNotificationReducer,
    fetchLocalKingForMapListReducer,
    getServicesAmountReducer,
    
} from "./user/reducer";

import {
    loginReducer,
    redirectReducer,
    registerReducer,
    updateMeReducer,
    getMeReducer,
    emailReducer,
    mobileReducer,
    verifyOtpReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
} from "./auth/reducer";

export default combineReducers({
    loginReducer,
    registerReducer,
    customerReducer,
    singleUserDetailsReducer,
    OrderReviewReducer,
    OrderReviewDeleteReducer,
    customerFilterReducer,
    EditUserReducer,
    localkingReducer,
    LocalKingFilterReducer,
    redirectReducer,
    updateMeReducer,
    getMeReducer,
    emailReducer,
    mobileReducer,
    orderReducer,
    serviceReducer,
    recentFiveOrderReducer,
    cancelOrderReducer,
    OrderFilterReducer,
    schedulePayoutReducer,
    getSchedulePayoutReducer,
    getCouponListReducer,
    getSingleCouponDetailsReducer,
    updateCouponReducer,
    createCouponReducer,
    updateServiceAmountReducer,
    fetchLocalKingPaymentListReducer,
    paymentmethodSettingReducer,
    createWalkinScreenReducer,
    fetchWalkInScreensReducer,
    deleteWalkInScreensReducer,
    fetchSingleWalkInScreenDetailsReducer,
    updateWalkInScreenReducer,
    verifyOtpReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
    getReadNotificationReducer,
    getUnreadNotificationReducer,
    fetchLocalKingForMapListReducer,
    getServicesAmountReducer,
});
