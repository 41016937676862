// import Widgets from "../components/widgets/Widgets";
import Widgetss from "../components/widgets/Widgetss";
import Layout from "../layout";

export default function Dashboard() {
    return (
        <Layout>
            <Widgetss />
        </Layout>
    );
}
