
import StripePaymentSettingForm from "../components/forms/StripePaymentSettingForm";
import PaypalPaymentSettingForm from "../components/forms/PaypalPaymentSettingForm";
import Layout from "../layout";

export default function PaymentSetting() {
    return (
        <Layout>
            <div className="accordion" id="accordionPaymentSetting">
                <StripePaymentSettingForm />
                <PaypalPaymentSettingForm />
            </div>
        </Layout>
    )
}
