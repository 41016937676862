import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import NavBar from "../custom/navBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../custom/Input";
import CustomButton from "../custom/Button";
import {
  editUser,
  fetchCustomer,
  fetchCustomerFilter,
  singleUserDetails,
} from "../../redux/user/action";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import Imageselector from "../custom/imageSelector";
export default function EditCustomerForm({ NameOfuser, role, name }) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [country, setCountry] = useState();
  const getUserData = useSelector(
    ({ singleUserDetailsReducer }) => singleUserDetailsReducer
  );
  const userData = getUserData?.items?.data;
  const { id } = state;
  const { currentPage } = state;

  const imageURL = process.env.REACT_APP_UPLOAD_URL;

  const useStyles = makeStyles({
    formBox: {},
    formGroup: {
      display: "flex",
      flexDirection: "column",
    },
    formControl: {
      border: "1px solid #E6EAF0 !important",
      height: "48px",
      borderRadius: "5px",
      padding: "0rem 1rem",
      width: "100%",
    },

    phoneCode: {
      border: "1px solid #E6EAF0",
      borderRadius: "5px",
      background: "transparent",
      width: "75px",
      padding: "0px 10px",
      fontSize: "14px",
      color: "#8E8E8E",
      "&:focus-visible": {
        outline: "none",
      },
    },
  });
  const classes = useStyles();
  const [getInputField, setInputField] = useState({});
  useEffect(() => {
    dispatch(singleUserDetails(id));
  }, [id]);
  useEffect(() => {
    setInputField({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      phoneCode: userData?.phoneCode,
      mobile: userData?.mobile,
      image: userData?.image,
    });
  }, [userData, getUserData]);

  const InputsObj = [
    {
      id: 1,
      label: "First Name",
      palceHolder: "First Name",
      type: "text",
      name: "firstName",
      requied: "requied",
      value:
        getInputField?.firstName == undefined ? "" : getInputField?.firstName,
    },
    {
      id: 2,
      label: "Last Name",
      palceHolder: "Last Name",
      type: "text",
      name: "lastName",
      requied: "requied",
      value:
        getInputField?.lastName == undefined ? "" : getInputField?.lastName,
    },
    {
      id: 3,
      label: "Email",
      palceHolder: "Email Address",
      type: "email",
      name: "email",
      requied: "requied",
      value: getInputField?.email == undefined ? "" : getInputField?.email,
    },

    {
      id: 5,
      label: "Mobile Number",
      palceHolder: "Mobile Number",
      type: "number",
      name: "mobile",
      requied: "requied",
      value: getInputField?.mobile == undefined ? "" : getInputField?.mobile,
    },
    {
      id: 6,
      imgLabel: "Photo",
      palceHolder: "Select Image",
      type: "file",
      name: "image",
      requied: "requied",
      value: getInputField?.image == undefined ? "" : getInputField?.image,
    },
  ];
  const register = useSelector(({ EditUserReducer }) => EditUserReducer);
  const navigate = useNavigate();

  const [disabledBtn, setDisabledBtn] = useState(true);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target?.files?.length > 0) {
      var file = e.target.files["0"];
      var reader = new FileReader();
      reader.onloadend = function () {
        setInputField({ ...getInputField, [name]: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      setInputField({ ...getInputField, [name]: value });
    }
    userData?.firstName !== value &&
    userData?.lastName !== value &&
    userData?.email !== value &&
    userData?.mobile !== value &&
    userData?.phoneCode !== value
      ? setDisabledBtn(false)
      : setDisabledBtn(true);
  };
  // const handleclick = async (e) => {
  //   e.preventDefault();
  //   // const responseData = await dispatch(
  //   //   editUser(id, getInputField, currentPage)
  //   // );
  //   // if (responseData.status === 200) {
  //   //   navigate("/users/List-of-Customers");
  //   // }
  // };

  const location = useLocation();

  return (
    <>
      {register.loading && (
        <Grid
          container
          justifyContent="center"
          style={{
            height: "123vh",
            width: "70%",
            position: "absolute",
          }}
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}

      <NavBar
        title="Users"
        subtitle="List of customers"
        thirdtitle="Edit Customer"
        url={location.pathname}
      />
      <div className="formBox">
        <form>
          <div className="row">
            <div className="col-md-6 mb-5">
              <h2
                style={{ color: "#1F4173", fontSize: "29px", fontWeight: 700 }}
              >
                {" "}
                Edit customer
              </h2>
            </div>
            <div className="col-md-6 mb-5">
              <a
                href="/users/List-of-Customers"
                className="mt-2 d-block font-19 font-weight-700 text-decoration-none text-end text-sapphire-glitter-color"
              >
                Back
              </a>
            </div>
          </div>

          <div className="row mb-5 mt-4">
            <div className="co-md-12 text-center">
              <Imageselector
                imgUrl={
                  getInputField?.image?.includes("base64")
                    ? getInputField?.image
                    : imageURL + getInputField?.image
                }
                handleChangeImage={handleChange}
              />
            </div>
          </div>

          <div className="row">
            {InputsObj.map((val) => {
              const cl = `col-lg-${val.type === "file" ? 12 : 6} col-sm-12`;
              return (
                <div className={cl} key={val.id}>
                  {val.name === "mobile" ? (
                    <label className="form-label d-block" htmlFor="input-field">
                      Mobile Number
                    </label>
                  ) : null}
                  <div
                    className={`mb-4 ${
                      val.name === "mobile" ? "d-flex" : null
                    }`}
                  >
                    {val.name === "mobile" ? (
                      <select
                        className={`me-2 ${classes.phoneCode}`}
                        name="phoneCode"
                        onChange={handleChange}
                        value={getInputField.phoneCode}
                      >
                        {getCountries().map((country) => (
                          <>
                            <option
                              key={country}
                              value={`+${getCountryCallingCode(country)}`}
                            >
                              + {getCountryCallingCode(country)}
                            </option>
                          </>
                        ))}
                      </select>
                    ) : null}

                    <InputField
                      id={val.id}
                      label={val.name !== "mobile" ? val.label : null}
                      type={val.type}
                      placeholder={val.palceHolder}
                      option={val.option}
                      phoneCode={getInputField.phoneCode}
                      handleChange={handleChange}
                      name={val.name}
                      imageUrl={getInputField.photo}
                      classStyle={classes}
                      value={val.value}
                      requied={val.requied}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row mt-5 pt-5 mb-5">
            <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
              <div className="row">
                <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                  <CustomButton
                    className={`btn font-weight-700 pb-2 pt-2 text-white`}
                    type="submit"
                    style={{
                      background: "#003AD2",
                      borderRadius: "8px",
                      width: "290px",
                      height: "55px",
                      fontSize: "26px",
                    }}
                    isdisabled={disabledBtn}
                    onClick={async (e) => {
                      e.preventDefault();
                      const responseData = await dispatch(
                        editUser(id, getInputField, currentPage)
                      );
                      if (responseData.status === 200) {
                        navigate("/users/List-of-Customers", {
                          state: {
                            currentPage: currentPage,
                          },
                        });
                      }
                    }}
                  >
                    Save
                  </CustomButton>
                </div>
                <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                  <CustomButton
                    style={{
                      color: "#1F4173",
                      width: "140px !important ",
                      textTransform: "capitalize",
                      height: "55px",
                      fontWeight: 700,
                      fontSize: "26px",
                    }}
                    onClick={() => {
                      //   dispatch(fetchCustomer(currentPage));
                      navigate("/users/List-of-Customers", {
                        state: {
                          currentPage: currentPage,
                        },
                      });
                    }}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
