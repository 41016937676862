import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAdminEmail, loginAdminEmailErrorShow } from "../../redux/auth/action";
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import ReactLoading from "react-loading";
import { Link, useNavigate } from "react-router-dom";
import localkings from "../../assets/images/icon.svg";
import { Grid } from "@mui/material";




export default function Login() {

    const useStyles = makeStyles({
        formGroup: {
            display: "flex",
            flexDirection: "column",
        },
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
        },
        label: {
            height: "100%",
            maxWidth: "100%",
            marginTop: "1em",
            fontWeight: 500,
            fontSize: "15px"
        },
        button: {
            background: "rgb(0, 58, 210)",
            width: "100%",
            height: "40px",
            border: "none",
            borderRadius: "5px",
            color: "white",
            marginTop: "10px",
            fontWeight: 600
        },
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getInputField, setInputField] = useState({
        email: "",
        password: "",
    });

    const currentUser = useSelector(({ loginReducer }) => loginReducer);
    const isRedirect = useSelector(
        ({ redirectReducer }) => redirectReducer.redirectTo
    );

    const InputsObj = [
        {
            id: 1,
            label: "Email/Mobile",
            palceHolder: "Email/Mobile",
            type: "text",
            name: "email",
            // value: getInputField.email,
        },
        {
            id: 2,
            label: "Password",
            type: "password",
            palceHolder: "Password",
            name: "password",
            // value: getInputField.password,
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...getInputField, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginAdminEmail(getInputField));
    };

    useEffect(() => {
        if (isRedirect !== undefined) {
            navigate("/");
        }
    }, [isRedirect, navigate]);

    return (
        <>
            {currentUser.loading ? (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h2 style={{ color: 'blue', marginRight: '20px' }}>Please Wait...</h2>
                        <CircularProgress style={{ color: 'blue' }} />
                    </div>
                </Grid>
            ) : (
                <>
                    <div id="main" className="formbox login_form">
                        <div className="box">
                            <div className="font-25 font-we text-center mb-4">
                                <img src={localkings} alt="" />
                                <span className="d-block mt-2 font-weight-700">LocalKings</span>
                            </div>

                            <form onSubmit={handleSubmit}>

                                {InputsObj.map((val) => {
                                    const cl = `col-lg-${val.type === "file" ? 12 : 12
                                        } col-sm-12`;
                                    return (
                                        <div className={`${cl} mb-4`} keys={val.id}>
                                            <InputField
                                                id={val.id}
                                                label={val.label}
                                                type={val.type}
                                                // value={val.value}
                                                placeholder={val.palceHolder}
                                                option={val.option}
                                                handleChange={handleChange}
                                                name={val.name}
                                                classStyle={classes}
                                            />
                                        </div>
                                    );
                                })}



                                <div style={{ display: "flex", marginTop: "20px" }}>
                                    <CustomButton type="submit" className={classes.button}  >
                                        Login
                                    </CustomButton>
                                </div>

                                <Link to="/forgotPassword" className="d-block font-18 font-weight-700 mt-2 text-primary">Forgot Password?</Link>

                            </form>
                        </div>
                    </div>
                </>
            )
            }
        </>
    );
}
