import authActionTypes from "./actionType";

const initialState = {
    currentUser: [],
    loading: false,
    error: null,
    isLogin: false,
    token: null,
    isVerified: false,
    isVerifiedEmail: false,
    resetPassword: false,
    isChangeEmail: false,
    isChangeMobile: false,
};

export const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REGISTER_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "REGISTER_SUCCESS":
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            };
        case "REGISTER_FAILURE":
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                isLogin: false,
            };
        case authActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                isLogin: true,
            };
        case authActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err,
                isLogin: false,
            };
        default:
            return state;
    }
};

export const updateMeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            };
        case "UPDATE_FAILURE":
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const getMeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ME_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "GET_ME_SUCCESS":
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            };
        case "GET_ME_FAILURE":
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EMAIL_UPDATE_REQUEST":
            return {
                ...state,
                loading: false,
                error: null,
                isChangeEmail: false,
            };
        case "EMAIL_UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                isChangeEmail: true,
                currentUser: action.payload,
            };
        case "EMAIL_UPDATE_FAILURE":
            return {
                ...state,
                loading: false,
                isChangeEmail: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const mobileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "MOBILE_UPDATE_REQUEST":
            return {
                ...state,
                loading: false,
                isChangeMobile: false,
                error: null,
            };
        case "MOBILE_UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                isChangeMobile: true,
                currentUser: action.payload,
            };
        case "MOBILE_UPDATE_FAILURE":
            return {
                ...state,
                loading: false,
                isChangeMobile: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const userDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DELETE_USER_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "DELETE_USER_SUCCESS":
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            };
        case "DELETE_USER_FAILURE":
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err,
            };
        default:
            return state;
    }
};

export const redirectReducer = (state = {}, action) => {
    switch (action.type) {
        case "REDIRECT":
            return { redirectTo: action.payload };
        default:
            return state;
    }
};

export const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.SEND_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                isVerifiedEmail: false,
            };
        case authActionTypes.SEND_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                isVerifiedEmail: true,
            };
        case authActionTypes.SEND_OTP_FAILURE:

            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err.response.data.message,
                isVerifiedEmail: false,
            };
        default:
            return state;
    }
};

export const verifyOtpReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.VERIFY_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                isVerified: false,
            };
        case authActionTypes.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                isVerified: true,
            };
        case authActionTypes.VERIFY_OTP_FAILURE:
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err.response.data.message,
                isVerified: false,
            };
        default:
            return state;
    }
};

export const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                isVerified: false,
                resetPassword: false,
            };
        case authActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                isVerified: true,
                resetPassword: true,
            };
        case authActionTypes.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                currentUser: [],
                error: action.err.response.data.message,
                isVerified: false,
                resetPassword: false
            };
        default:
            return state;
    }
};
