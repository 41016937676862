import React, { useState, memo } from "react";
import { AppBar, IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import SidebarDrawer from "./sidebarDrawer";
import "./styles.modules.css";
import data from "./sidebarData";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginLeft: "-80%",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

function ResponsiveDrawer() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sideBarData, setSideBarData] = useState(data);

  const handleDrawerToggle = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <Menu />
        </IconButton>
      </AppBar>
      <SidebarDrawer
        setSideBarData={setSideBarData}
        sideBarData={sideBarData}
        side
        open={open}
        setOpen={setOpen}
        direction="left"
      />
    </div>
  );
}
export default memo(ResponsiveDrawer);
