import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Layout from "../layout";
import Table from "../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchLocalKingPaymentList } from "../redux/user/action"; 

const useStyles = makeStyles({
    component: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    title: {
        fontWeight: "bold",
        fontSize: "25px",
        lineHeight: "38px",
        color: "#003AD2",
        marginBottom: "2em",
    },
});

const tableHead = [
    { id: 1, title: "S.No." },
    // { id: 2, title: "Service ID" },
    { id: 4, title: "Localking" },
    { id: 8, title: "Total Amount" },
    { id: 9, title: "Status" },
    // { id: 10, title: "View" },
];

export default function ListPayments() {
    const [filter, setFilter] = useState(null)
    const [filterCountNumber, setFilterCountNumber] = useState(1)
    const classes = useStyles();
    const dispatch = useDispatch();

    const payments = useSelector(
        ({ fetchLocalKingPaymentListReducer }) => fetchLocalKingPaymentListReducer
    );
     
    useEffect(() => {
        dispatch(fetchLocalKingPaymentList(filter, filterCountNumber))
    }, [filter, filterCountNumber])

    const setfilterVal = (val) => {
        setFilter(val)
    }

    const setFilterCount = (count) => {
        setFilterCountNumber(count)
    }

    
    const listPayment = payments?.items; 

    return (
        <>
            <Layout>
                <Box  className={classes.component}>
                    <Box>
                        {payments.loading && (
                            <Grid
                                container
                                justifyContent="center"
                                style={{
                                    height: "100vh",
                                    width: "100%",
                                    position: "absolute",
                                }}
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        <Table
                            name="Payouts and Payout Methods"
                            subtitle="Payout List"
                            tableTitle="Payout List"
                            tableHead={tableHead}
                            tableBody={listPayment}
                            paginate={true}
                            type="paymentList"
                            TableFilterCoun={0}
                            items="false"
                            setfilterVal={setfilterVal}
                            setFilterCount={setFilterCount}
                            filtertransactions={payments}
                        />
                    </Box>
                </Box>
            </Layout>
        </>
    );
}
