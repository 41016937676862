import "./Card.modules.css";
export default function Card({ color, title, quantity, description }) {
  return (
    <div style={{ background: color }} className="widget_card">
      <div className="">
        <h4>{title}</h4>
        <h1>{quantity}</h1>
      </div>
    </div>
  );
}
