import axios from "axios";
import swal from "sweetalert";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

import {
    fetchOrder,
    fetchServices,
    fetchLocalkings,
    fetchCustomer,
    recentFiveOrders,
    cancelledOrder,
} from "../user/action";

import sweetAlert from "./../../sweetAlertMessages";
import authActionTypes from "./actionType";
import { axiosErrorHandler, responseModel } from "../../utils/responseModel";

axios.defaults.baseURL = process.env.REACT_APP_URL_DEV;

export const redirectPath = (link) => {
    return {
        type: authActionTypes.REDIRECT,
        payload: link,
    };
};

export const registerUser = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: authActionTypes.REGISTER_REQUEST });
    try {
        const response = await axios.post("/users", data, {
            headers,
        });
        dispatch({
            type: authActionTypes.REGISTER_SUCCESS,
            payload: response.data,
        });
        const notyf = new Notyf({
            duration: 8000,
            position: {
                x: 'center',
                y: 'top',
            },

            types: [
                {
                    type: 'successs',
                    ripple: true,
                    icon: true,
                }
            ]
        });

        notyf.open({
            type: 'success',
            message: 'Customer Added Successfuly'
        });
        return responseModel(response.status, response.data);

    } catch (err) {
        const response = axiosErrorHandler(err);
        dispatch({ type: authActionTypes.REGISTER_FAILURE, err });
        swal(response?.data?.message, {
            icon: "error",
            timer: 4000,
            button: false,
        });
        return responseModel(response?.status, response?.data)
    }
};

export const loginAdminEmail = (data) => async (dispatch, getState) => {

    dispatch({ type: authActionTypes.LOGIN_REQUEST });
    try {
        const response = await axios.post("/users/loginForAdmin", data);
        dispatch({
            type: authActionTypes.LOGIN_SUCCESS,
            payload: response.data,
        });
        localStorage.setItem("token", response.data.token);
        swal(sweetAlert.LOGIN_SUCCESS, {
            icon: "success",
            timer: 4000,
            button: false,

        });

        dispatch(fetchOrder());
        dispatch(fetchServices());
        dispatch(recentFiveOrders());
        dispatch(cancelledOrder());
        dispatch(fetchLocalkings());
        dispatch(fetchCustomer());
        dispatch(redirectPath("/"));
    } catch (err) {
        dispatch({ type: authActionTypes.LOGIN_FAILURE, err });
        swal({
            text: err?.response?.data?.message,
            icon: "error",
            timer: 4000,
            button: false,
        });
    }

};

export const getMe = () => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: authActionTypes.GET_ME_REQUEST });
    try {
        const response = await axios.get("/users/me", {
            headers,
        });
        dispatch({ type: authActionTypes.GET_ME_SUCCESS, payload: response.data });
    } catch (err) {
        dispatch({ type: authActionTypes.GET_ME_FAILURE, err });
    }
};

export const updateMe = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: authActionTypes.UPDATE_REQUEST });
    try {
        const response = await axios.patch("/users/me", data, {
            headers,
        });
        dispatch({ type: authActionTypes.UPDATE_SUCCESS, payload: response.data });
        dispatch(getMe(token)); 
        const notyf = new Notyf({
            duration: 5000,
            position: {
                x: 'center',
                y: 'top',
            },

            types: [
                {
                    type: 'successs',
                    ripple: true,
                    icon: true,
                }
            ]
        });

        notyf.open({
            type: 'success',
            message: 'Profile Edited Successfuly'
        });
    } catch (err) {
        dispatch({ type: authActionTypes.UPDATE_FAILURE, err });
        swal(err.response.data.message, {
            buttons: false,
            timer: 4000,
            icon: "error",
        });
    }
};

export const updateEmailSetinitialStateFalse = () => async (dispatch) => {
    dispatch({
        type: authActionTypes.EMAIL_UPDATE_REQUEST
    });
};

export const updateEmail = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: authActionTypes.EMAIL_UPDATE_REQUEST });
    try {
        const response = await axios.patch("/users/changeEmail", data, {
            headers,
        });

        dispatch({
            type: authActionTypes.EMAIL_UPDATE_SUCCESS,
            payload: response.data.data,
        });

    } catch (err) {
        dispatch({ type: authActionTypes.EMAIL_UPDATE_FAILURE, err });

    }
};

export const updateMobileSetinitialStateFalse = () => async (dispatch) => {
    dispatch({
        type: authActionTypes.MOBILE_UPDATE_REQUEST
    });
};

export const updateMobile = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: authActionTypes.MOBILE_UPDATE_REQUEST });
    try {
        const response = await axios.patch("/users/changeMobile", data, {
            headers,
        });

        dispatch({
            type: authActionTypes.MOBILE_UPDATE_SUCCESS,
            payload: response.data.data,
        });

    } catch (err) {
        dispatch({ type: authActionTypes.MOBILE_UPDATE_FAILURE, err });
    }
};

export const sendOtpSetinitialStateFalse = () => async (dispatch) => {
    dispatch({ type: authActionTypes.SEND_OTP_REQUEST });
};

export const sendOtp = (data) => async (dispatch, getState) => {
    dispatch({ type: authActionTypes.SEND_OTP_REQUEST });
    try {
        const response = await axios.post("/users/sendOtp", data);
        dispatch({
            type: authActionTypes.SEND_OTP_SUCCESS,
            payload: response.data,
        });


    } catch (err) {

        dispatch({ type: authActionTypes.SEND_OTP_FAILURE, err });
    }
};

export const verifyOtpSetinitialStateFalse = () => async (dispatch) => {
    dispatch({ type: authActionTypes.VERIFY_OTP_REQUEST });
};

export const verifyOtp = (data) => async (dispatch, getState) => {
    dispatch({ type: authActionTypes.VERIFY_OTP_REQUEST });
    try {
        const response = await axios.patch("/users/verifyOtp", data);
        dispatch({
            type: authActionTypes.VERIFY_OTP_SUCCESS,
            payload: response.data,
        });

    } catch (err) {
        dispatch({ type: authActionTypes.VERIFY_OTP_FAILURE, err });
    }
};

export const resetPasswordSetinitialStateFalse = () => async (dispatch) => {
    dispatch({ type: authActionTypes.RESET_PASSWORD_REQUEST });
};

export const resetPassword = (data) => async (dispatch, getState) => {
    dispatch({ type: authActionTypes.RESET_PASSWORD_REQUEST });
    try {
        const response = await axios.patch("/users/resetPassword", data);
        dispatch({
            type: authActionTypes.RESET_PASSWORD_SUCCESS,
            payload: response.data,
        });

        const notyf = new Notyf({
            duration: 10000,
            position: {
                x: 'center',
                y: 'top',
            },

            types: [
                {
                    type: 'successs',
                    ripple: true,
                    icon: true,
                }
            ]
        });

        notyf.open({
            type: 'success',
            message: 'Password reset Successfuly'
        });


    } catch (err) {
        dispatch({ type: authActionTypes.RESET_PASSWORD_FAILURE, err });
    }
};

export const sendOtpToChangeEmailOrMobile = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: authActionTypes.SEND_OTP_REQUEST });
    try {
        const response = await axios.post("/users/otp-profile-update", data, {
            headers
        });
        dispatch({
            type: authActionTypes.SEND_OTP_SUCCESS,
            payload: response.data,
        });


    } catch (err) {

        dispatch({ type: authActionTypes.SEND_OTP_FAILURE, err });
    }
};





