import EditWalkInScreenForm from "../components/forms/EditWalkInScreenForm";
import Layout from "../layout";

export default function EditWalkInScreen() {
    return (
        <Layout>
            <EditWalkInScreenForm />
        </Layout>
    );
}
