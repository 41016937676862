
import AddCustomerForm from "../components/forms/addcustomer";
import Layout from "../layout";

export default function AddCustomer({ NameOfuser }) {
  return (
    <Layout>
      <AddCustomerForm NameOfuser={NameOfuser} />
    </Layout>
  )
}
