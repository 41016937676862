import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Layout from "../layout";
import Table from "../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { fetchCustomer, fetchCustomerFilter } from "../redux/user/action";
import debounce from "lodash.debounce";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  component: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
  },
  progress: {
    display: "flex",
    justifyContent: "space-between",
    padding: "35px 0px",
  },
});

const tableHead = [
  { id: 1, title: "S.No" },
  { id: 2, title: "First Name" },
  { id: 3, title: "Last Name" },
  { id: 4, title: "Email" },
  { id: 5, title: "Mobile" },
  { id: 6, title: "Rating" },
  { id: 7, title: "Localking" },
  { id: 8, title: "Status" },
  { id: 9, title: "Action" },
];

export default function ListCustomer() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(
    state?.currentPage ? state?.currentPage : 1
  );
  const classes = useStyles();
  const [data, setSearchData] = useState("");
  const [dbvalue, setDbvalue] = useState("");
  const [filterCountNumber, setFilterCountNumber] = useState(1);

  const customersFilter = useSelector(
    ({ customerFilterReducer }) => customerFilterReducer
  );

  const listFilterCustomer = customersFilter?.items;
  const FilterCustomercount =
    customersFilter?.items?.count == undefined
      ? 0
      : customersFilter?.items?.count;

  const dispatch = useDispatch();

  const debounsedSaved = useCallback(
    debounce((data) => setDbvalue(data), 1000),
    []
  );
  debounsedSaved(data);

  const setFilterCount = (count) => {
    setFilterCountNumber(count);
  };

  const count =
    FilterCustomercount == 0
      ? filterCountNumber
      : Math.ceil(FilterCustomercount / 10);

  useEffect(() => {
      dispatch(fetchCustomerFilter(dbvalue, filterCountNumber));
  }, [dbvalue, count, filterCountNumber]);

  useEffect(() => {
    if (dbvalue != "") {
      setFilterCountNumber(1);
      dispatch(fetchCustomerFilter(dbvalue, filterCountNumber));
      return
    }
    if (dbvalue == "" || dbvalue == null) {
      setFilterCountNumber(1);
      dispatch(fetchCustomerFilter(dbvalue, filterCountNumber));
      return
    }
  }, [dbvalue]);




  return (
    <>
      <Layout>
        <Box className={classes.component}>
          <Box>
            {customersFilter.loading && (
              <Grid
                container
                justifyContent="center"
                style={{
                  height: "110vh",
                  width: "100%",
                  position: "absolute",
                }}
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            )}
            <Table
              name="Users"
              subtitle="List of Customer"
              tableTitle="Customers"
              tableHead={tableHead}
              type="customerList"
              tableBody={listFilterCustomer}
              filtercount={listFilterCustomer?.data || []}
              isLoadingData={customersFilter?.loading || false}
              TableFilterCoun={FilterCustomercount}
              paginate={true}
              dbvalue={dbvalue || ""}
              NavigateLocation={"add-customer"}
              setFilterCount={setFilterCount}
              setSearchData={setSearchData}
              initCurrentPage={state?.currentPage || 0}
              filterCountNumber={filterCountNumber}
            />
          </Box>
        </Box>
      </Layout>
    </>
  );
}
