import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SidebarList from "./SidebarList";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function SidebarNav({ item, sideBarData, setSideBarData }) {
  const classes = useStyles();
  const [allClose, setAllClose] = useState(false);

  const handleClick = (id, arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (id === arr[i].id) {
        arr[i].isOpen = !arr[i].isOpen;
      } else {
        arr[i].isOpen = false;
      }
    }
    setAllClose(false);
    setSideBarData((prev) => {
      return [...prev, arr];
    });
  };

  const handleClose = ()=>{
    setAllClose(true)
  }

  return (
    <List className={classes.root} key={item.id}>
      <div key={item.id}>
        {item.title == "Dashboard" ||
        item.title == "Transaction" ||
        item.title == "Map" ? (
            <SidebarList
              key={item.id}
              item={item}
              handleClose={() => handleClick(item.id, sideBarData)}
            />
        ) : (
          <>
            <div key={item.id}>
              <ListItem
                style={{ paddingLeft: "32px !important" }}
                button
                key={item.id}
                onClick={() => handleClick(item.id, sideBarData)}
              >
                <ListItemIcon className="collapseIcon">
                  {item.icon}
                </ListItemIcon>
                <ListItemText className="collapseTitle" primary={item.title} />
                {sideBarData[item.id - 1].isOpen ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                key={item.title}
                component="li"
                in={item.isOpen ? item.isOpen : false}
                timeout="auto"
                unmountOnExit
                className="innerListItem"
              >
                {item.subNav.map((item) => {
                  return <SidebarList key={item.id} item={item} />;
                })}
              </Collapse>
            </div>
          </>
        )}
      </div>
      <div>
        <a className="active"></a>
      </div>
    </List>
  );
}
