import { Button, makeStyles } from "@material-ui/core";
import { Pagination } from "@mui/material";
import React, { memo, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Paginate = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
        // props.dbvalue ? props?.onChange(1) : props.onChange(props.currentPage)
        if(props.dbvalue){
            props?.onChange(1)
            return
        }
        if(props.dbvalue == "" || props.dbvalue == null){
            props?.onChange(1)
            return
        }
        props.onChange(props.currentPage)
    },[props.dbvalue])
    useEffect(()=>{
        props?.filtervalue ? props?.onChange(1) : props?.onChange(props.currentPage);
        //  props?.onChange(props.currentPage);
    },[props.filtervalue])

    const [page, setPage] = useState(1);
    props?.callbackData(page);
    const handleChange = (e, currentPage) => {
        setPage(currentPage);
        props.setFilterCount(currentPage);
        props?.onChange(currentPage);
    };

    const paginate = props.filtercount == undefined ? props.totalData : props.filtercount
    // const filterPagination = props.countData
    const filterListcount = props.TableFilterCoun == undefined ? 0 : props.TableFilterCoun


    return (
        <Pagination className="custom_pagination"
            onChange={(e, currentPage) => handleChange(e, currentPage)}
            count={!props.dbvalue && filterListcount == 0 ? Math.ceil(paginate / 10) : Math.ceil(filterListcount / 10)}
            page={props.getPage != 0 ? props.getPage : page}
            color="primary"
        // showFirstButton
        // showLastButton
        />
    );
};

export default memo(Paginate);
