import React, { useState } from "react";
import CustomButton from "../custom/Button";
import NavBar from "../custom/navBar";
import InputField from "../custom/Input";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createCoupon } from "../../redux/user/action";
import swal from "sweetalert";

const AddCouponForm = () => {

    const useStyles = makeStyles({
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
            width: "100%",
        },
        label: {

        }
    });
    const classes = useStyles();

    const [getInputField, setInputField] = useState({
        couponCode: "",
        couponUses: "",
        couponAmount: 0,
        startDate: "",
        expireDate: "",
        amountType: "",
        service: "",
        serviceType: "",
    });

    const InputsObj = [

        {
            id: 1,
            label: "Coupon Name/Coupon Code",
            palceHolder: "Enter coupon name",
            type: "text",
            name: "couponCode",
            requied: "requied",
        },

        {
            id: 3,
            label: "Usage",
            palceHolder: "Enter number of times the coupon can be used",
            type: "number",
            name: "couponUses",
            requied: "requied",
        },

        {
            id: 5,
            label: "From",
            palceHolder: "DD/MM/YYYY",
            type: "datetime-local",
            name: "startDate",
            requied: "requied",
            min: new Date().toISOString().slice(0, 16),
        },

        {
            id: 4,
            label: "To",
            palceHolder: "DD/MM/YYYY",
            type: "datetime-local",
            name: "expireDate",
            requied: "requied",
            min: getInputField.startDate === "" ? new Date().toISOString().slice(0, 16) : getInputField.startDate,
        },

        {
            id: 6,
            label: "Service",
            palceHolder: "fixed",
            type: "radio",
            name: "service",
            requied: "requied",
            option: [
                { id: 1, value: 'Return', name: "Return" },
                { id: 2, value: 'Delivery', name: "Delivery" },
            ],
        },

        {
            id: 7,
            label: "Service Type",
            palceHolder: "fixed",
            type: "radio",
            name: "serviceType",
            requied: "requied",
            option: [
                { id: 1, value: 'Bring', name: "Bring" },
                { id: 2, value: 'Fetch', name: "Fetch" },
            ],
        },

        {
            id: 7,
            label: "Type of coupon",
            palceHolder: "fixed",
            type: "select",
            name: "amountType",
            requied: "requied",
            selector: {
                height: "100%",
                padding: "10px",
                maxWidth: "100%",
                marginTop: "1em",
                width: "calc(100% - 330px)",
            },
            option: [
                { id: 1, value: '', name: "Please Select" },
                { id: 2, value: 1, name: "Fixed" },
                { id: 3, value: 2, name: "Percentage" },
            ],
        },

        {
            id: 8,
            label: "Amount",
            palceHolder: "01.00 €",
            type: "number",
            name: "couponAmount",
            requied: "requied",
        },


    ];

    const register = useSelector(({ registerReducer }) => registerReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target?.files?.length > 0) {
            var file = e.target.files["0"];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputField({ ...getInputField, [name]: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            setInputField({ ...getInputField, [name]: value });
        }
    };

    const handleclick = async (e) => {
        if (getInputField.couponAmount.length > 4) {
            swal("Error!", "Please Enter Limited coupon amount!", "error");
            return;
        }

        if (!getInputField.couponCode.trim()) {
            swal("Error!", "Coupon Name/Coupon Code field is required!", "error");
            return;
        }

        if (!getInputField.couponUses.trim()) {
            swal("Error!", "Usage field is required!", "error");
            return;
        }

        if (!getInputField.couponAmount.trim()) {
            swal("Error!", "Coupon amount field is required!", "error");
            return;
        }

        if (!getInputField.startDate.trim()) {
            swal("Error!", "Coupon start date field is required!", "error");
            return;
        }

        if (!getInputField.expireDate.trim()) {
            swal("Error!", "Expire date field is required!", "error");
            return;
        }

        if (!getInputField.service.trim()) {
            swal("Error!", "Service field is required!", "error");
            return;
        }

        if (!getInputField.serviceType.trim()) {
            swal("Error!", "Service type field is required!", "error");
            return;
        }

        if (!getInputField.amountType.trim()) {
            swal("Error!", "Amount type field is required!", "error");
            return;
        }

        e.preventDefault();
        const responseData = await dispatch(createCoupon(getInputField));
        if (responseData.status === 200) {
            navigate("/coupon-list")
        }

    };

    const location = useLocation();
    return (
        <>
            {register.loading && (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >

                </Grid>
            )}
            <NavBar title="Coupons" url={location.pathname} />

            <div className="formBox">

                <h2 className="font-29 font-weight-700 mb-5" style={{ color: "#1F4173" }}> Add Coupon </h2>

                <div className="row">
                    <div className="col-md-12">

                        {InputsObj.map((val, index) => {
                            const cl = `col-lg-${val.type === "datetime-local" ? 4 : 8} col-xxl-${val.type === "datetime-local" ? 3 : 6} col-md-${val.type === "datetime-local" ? 6 : 6} col-sm-12 mb-4`;

                            return (
                                <>
                                    {index === 2 ?
                                        <div className="col-md-12">
                                            <div className="mb-4 mt-3">
                                                <label className="form-label d-block">Validity</label>
                                            </div>
                                        </div> : null
                                    }

                                    <div className={`${cl} ${val.type === "datetime-local" ? "d-lg-inline-block d-md-block" : ""} ${index === 2 ? "pe-1" : ""} ${index === 3 ? "ps-1" : ""}`} key={val.id}>
                                        <InputField
                                            id={val.id}
                                            label={val.label}
                                            type={val.type}
                                            radio={val.radio}
                                            placeholder={val.palceHolder}
                                            option={val.option}
                                            handleChange={handleChange}
                                            name={val.name}
                                            requied={val.requied}
                                            imageUrl={getInputField.photo}
                                            classStyle={classes}
                                            className="custom-radio-btn"
                                            minDate={val.min}
                                            maxDate={val.max}
                                        />
                                    </div>

                                </>
                            );
                        })}

                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                        <div className="row">
                            <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                <CustomButton
                                    className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                    type="submit"
                                    style={{
                                        background: "#003AD2",
                                        borderRadius: "8px",
                                        width: "290px",
                                        height: "55px",
                                        fontSize: "26px"
                                    }}
                                    onClick={handleclick}> Save
                                </CustomButton>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                <CustomButton
                                    style={{
                                        color: "#1F4173",
                                        width: "140px !important ",
                                        textTransform: "capitalize",
                                        height: "55px",
                                        fontWeight: 700,
                                        fontSize: "26px"
                                    }}
                                    onClick={() => navigate("/coupon-list")}> Cancel
                                </CustomButton>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    );
};

export default AddCouponForm;
