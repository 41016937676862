import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useEffect, useState } from "react";

const InputField = ({
  id,
  value,
  label,
  name,
  placeholder,
  type,
  handleChange,
  option,
  imageUrl,
  handleMobileChange,
  phoneCode,
  classStyle,
  className,
  disabled,
  defaultValue,
  checked,
  maxLength,
  required,
  minDate,
  maxDate,
  min,
  max,
  toolip,
}) => {
  const [mob, setMob] = useState("");

  useEffect(() => {
    if (type === "mobile" && value && phoneCode) {
      setMob(phoneCode.concat(value));
    } else {
      setMob(setMob(""));
    }
  }, [value, phoneCode]);

  return (
    <>
      {type === "select" ? (
        <>
          <label className="form-label d-block" htmlFor="input-field">
            {label}
          </label>

          <select
            id={id}
            type={type}
            value={value}
            required
            name={name}
            className={`bg-white font-13 focus-remove w-100 ps-3  placeholder-color input-field`}
            placeholder={placeholder}
            onChange={handleChange}
          >
            {option.map((val) => {
              return (
                <option key={val.id} value={val.value || imageUrl}>
                  {val.name}
                </option>
              );
            })}
          </select>
        </>
      ) : type === "text" ||
        type === "date" ||
        type === "email" ||
        type === "tel" ||
        type === "datetime-local" ||
        type === "password" ? (
        <>
          <label
            className={`${classStyle.label} form-label d-block`}
            htmlFor="input-field"
          >
            {label}
          </label>

          <input
            id={id}
            type={type}
            defaultValue={defaultValue}
            value={value}
            name={name}
            required
            maxLength
            min={minDate}
            max={maxDate}
            className={
              name === "oldEmail" || name === "oldMobile"
                ? `font-13 focus-remove w-100 placeholder-color ${classStyle.formControlDefault}`
                : `font-13 focus-remove w-100 placeholder-color ${classStyle.formControl}`
            }
            placeholder={placeholder}
            onChange={handleChange}
            disabled={disabled}
          />
        </>
      ) : type === "number" ? (
        <>
          <label
            className={`${classStyle.label} form-label d-block`}
            htmlFor="input-field"
          >
            {label}
          </label>

          <input
            id={id}
            type={type}
            defaultValue={defaultValue}
            value={value}
            name={name}
            required
            min={min}
            max={max}
            className={`font-13 focus-remove w-100 placeholder-color ${classStyle.formControl}`}
            placeholder={placeholder}
            onChange={handleChange}
            disabled={disabled}
          />
        </>
      ) : type === "mobile" ? (
        <>
          <label
            className={`${classStyle.label} form-label d-block`}
            htmlFor="input-field"
          >
            {label}
          </label>
          <PhoneInput
            international
            placeholder={placeholder}
            id={id}
            value={mob}
            name={name}
            required
            style={{ border: "none !important" }}
            className={`CustomPhone w-100 ${classStyle.formControl}`}
            defaultValue={defaultValue}
            onChange={handleMobileChange}
            disabled={disabled}
          />
        </>
      ) : type === "textarea" ? (
        <>
          <label
            className={`${classStyle.label} form-label d-block`}
            htmlFor="input-field"
          >
            {label}
          </label>
          <textarea
            id={id}
            type={type}
            defaultValue={defaultValue}
            value={value}
            name={name}
            required
            maxLength
            min={minDate}
            max={maxDate}
            className={`font-13 focus-remove w-100 placeholder-color ${classStyle.textarea}`}
            placeholder={placeholder}
            onChange={handleChange}
            disabled={disabled}
            rows="6"
            cols="50"
          >
            {value}
          </textarea>
        </>
      ) : type === "radio" ? (
        <>
          <label
            className={`${classStyle.label} form-label d-block mb-3`}
            htmlFor="input-field"
          >
            {" "}
            {label}{" "}
          </label>
          {option.map((val) => {
            return (
              <>
                <div className={`${className} form-check-inline`}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`${name}`}
                    id={`${name}_${val.id}`}
                    value={`${val.value}`}
                    onChange={handleChange}
                    checked={val.checked}
                  />
                  <label
                    className="font-14 form-check-label text-muted"
                    for={`${name}_${val.id}`}
                  >
                    {" "}
                    {val.name}{" "}
                  </label>
                </div>
              </>
            );
          })}
        </>
      ) : type === "checkbox" ? (
        <>
          <label className={`${classStyle.label} mb-2`} htmlFor="input-field">
            {" "}
            {label}{" "}
          </label>
          {option.map((val) => {
            return (
              <>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type={`${type}`}
                    name={`${name}`}
                    id={`radio${val.id}`}
                    value={`${val.value}`}
                    onChange={handleChange}
                  />
                  <label
                    className="font-14 form-check-label text-muted"
                    for={`radio${val.id}`}
                  >
                    {" "}
                    {val.name}{" "}
                  </label>
                </div>
              </>
            );
          })}
        </>
      ) : type === "hr" ? (
        <>
          <hr className={classStyle.hr}></hr>
        </>
      ) : null}
    </>
  );
};

export default InputField;
