import React, { useEffect, useState } from "react";
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import Imageselector from "../custom/imageSelector";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { getMe, updateMe } from "../../redux/auth/action";
import NavBar from "../custom/navBar";
import swal from "sweetalert";

export default function EditProfileForm() {

    const useStyles = makeStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        formGroup: {
            display: "flex",
            flexDirection: "column",
        },
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
            '&:disabled': {
                background: "#EDEDED",
            }
        },
        editButton: {
            background: "white",
            border: "none",
            color: "blue",
            fontWeight: "bold",
            fontSize: "16px",
            cursor: "pointer",
        },
        button: {
            background: "rgb(0, 58, 210)",
            width: "204px",
            border: "none",
            borderRadius: "5px",
            color: "white",
            cursor: "pointer",
        },
        CountryCallingCodeInput: {
            background: "#EDEDED",
            border: "1px solid #E6EAF0 !important",
            borderRadius: "5px",
            width: "75px",
            padding: "0px 10px",
            fontSize: "13px",
            textAlign: "center"
        },
    });
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector(({ getMeReducer }) => getMeReducer);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [getInputField, setInputField] = useState({})
    useEffect(() => {
        dispatch(getMe());
    }, []);
    useEffect(() => {
        setInputField({
            firstName: currentUser?.currentUser.data?.firstName,
            lastName: currentUser?.currentUser.data?.lastName,
            email: currentUser?.currentUser.data?.email,
            mobile: currentUser?.currentUser.data?.mobile,
            image: currentUser?.currentUser.data?.image,
            currentRole: 'Admin'
        });
    }, [currentUser]);

    const InputsObj = [
        {
            id: 1,
            label: "First Name  ",
            palceHolder: "First Name",
            type: "text",
            name: "firstName",
            value: getInputField.firstName,
        },
        {
            id: 2,
            label: "Last Name",
            palceHolder: "Last Name",
            type: "text",
            name: "lastName",
            value: getInputField.lastName,
        },
        {
            id: 3,
            label: "Email",
            palceHolder: "Email Address",
            type: "email",
            name: "email",
            value: getInputField.email,
        },
        {
            id: 5,
            label: "Mobile Number",
            palceHolder: "Mobile Number",
            type: "text",
            name: "mobile",
            value: getInputField.mobile,
        },
        {
            id: 6,
            imgLabel: "Photo",
            palceHolder: "Select Image",
            type: "file",
            name: "image",
            value: getInputField.image,
        },
    ];

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target?.files?.length > 0) {
            var file = e.target.files["0"];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputField({ ...getInputField, [name]: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            setInputField({ ...getInputField, [name]: value });
        }
        value !== "" ? currentUser?.currentUser.data?.firstName !== value && currentUser?.currentUser.data?.lastName !== value ? setDisabledBtn(false) : setDisabledBtn(true)
            : value === "" ? setDisabledBtn(true) : setDisabledBtn(false)
    };
    const handleclick = (e) => {
        e.preventDefault();
        dispatch(updateMe(getInputField));
        setTimeout(() => {
            navigate('/settings/profile')
        }, 600);
    };
    return (
        <>

            {currentUser.loading ? (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "135vh",
                        width: "72%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <CircularProgress />
                </Grid>
            ) : null}
            <NavBar title="Settings" url={location.pathname} />

            <div className="formBox">

                <div className="row">
                    <div className="col-md-6">
                        <h2 className="mb-4 font-29 font-weight-700" style={{ color: "#1F4173" }}>
                            Edit Profile
                        </h2>
                    </div>
                    <div className="col-md-6 text-end">
                        <CustomButton
                            onClick={() => {
                                setDisabled(!disabled);
                                navigate("/settings/profile");
                            }}
                            className={classes.editButton}>
                            Back
                        </CustomButton>
                    </div>
                </div>

                <div className="row mb-5 mt-4">
                    <div className="co-md-12 text-center">
                        <Imageselector imgUrl={getInputField?.image?.includes("base64") ? getInputField?.image : process.env.REACT_APP_UPLOAD_URL + getInputField?.image} handleChangeImage={handleChange} />
                    </div>
                </div>

                <div className="row">
                    {InputsObj.map((val) => {
                        const cl = `col-lg-${val.type === "file" ? 12 : 6} col-sm-12`;
                        return (
                            <>
                                <div className={cl} key={val.id}>
                                    {val.name === "mobile" ? <label className="form-label d-block" htmlFor="input-field">Mobile Number</label> : null}

                                    <div className={`mb-4 ${val.name === "mobile" ? "d-flex" : null}`}>
                                        {val.name === "mobile" ? <input type="text" className={`me-2 ${classes.CountryCallingCodeInput}`} value={currentUser?.currentUser?.data?.phoneCode} disabled /> : null}
                                        <InputField
                                            id={val.id}
                                            label={val.name !== "mobile" ? val.label : null}
                                            type={val.type}
                                            value={val.value}
                                            placeholder={val.palceHolder}
                                            option={val.option}
                                            handleChange={handleChange}
                                            name={val.name}
                                            classStyle={classes}
                                            disabled={val.name == 'mobile' ? disabled : val.name == 'email' ? disabled : ''}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>



                <div className="row mt-4">
                    <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                        <div className="row">

                            <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                                <CustomButton
                                    type="submit"
                                    className={`btn font-weight-700 pb-2 pt-2 text-white`}
                                    style={{
                                        background: "#003AD2",
                                        borderRadius: "8px",
                                        width: "290px",
                                        height: "55px",
                                        fontSize: "26px"
                                    }}
                                    onClick={handleclick}
                                    isdisabled={disabledBtn}
                                >
                                    Save Changes
                                </CustomButton>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                                <CustomButton
                                    style={{
                                        color: "#1F4173",
                                        width: "140px !important ",
                                        textTransform: "capitalize",
                                        height: "55px",
                                        fontWeight: 700,
                                        fontSize: "26px"
                                    }}
                                    onClick={() => navigate("/settings/profile")}>
                                    Cancel
                                </CustomButton>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}
