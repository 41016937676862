
import { useDispatch } from "react-redux";
import Payoutsetting from "../components/forms/payoutsetting";
import Layout from "../layout"; 

export default function Payout() {
    const dispatch = useDispatch()
    return (
        <Layout>
            <Payoutsetting />
        </Layout>
    );
}