import React, { useCallback, useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import ReactLoading from "react-loading";
import Table from "../components/table/Table";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCoupon } from "../redux/user/action";
import debounce from "lodash.debounce";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  component: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
  },
  progress: {
    display: "flex",
    justifyContent: "space-between",
    padding: "35px 0px",
  },
});

const tableHead = [
  { id: 1, title: "S.No." },
  { id: 2, title: "Coupon Name/Code" },
  { id: 3, title: "Service" },
  { id: 4, title: "Service Type" },
  { id: 5, title: "Expiry Date" },
  { id: 6, title: "Amount/Percentage" },
  { id: 7, title: "Utilized Coupons" },
  { id: 8, title: "Action" },
];

const CouponList = () => {
  const { state } = useLocation();
  const [data, setSearchData] = useState(null);
  const [dbvalue, setDbvalue] = useState(null);

  const [filter, setFilter] = useState(null);
  const [filterCountNumber, setFilterCountNumber] = useState(1);
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCoupon = useSelector(
    ({ getCouponListReducer }) => getCouponListReducer
  );

  const listCoupon = getCoupon?.items;

  const FilterCouponcount =
    getCoupon?.items?.count === undefined ? 0 : getCoupon?.items?.count;

  const debounsedSaved = useCallback(
    debounce((data) => setDbvalue(data), 1000),
    []
  );
  debounsedSaved(data);

  useEffect(() => {
    dispatch(fetchCoupon(filterCountNumber,dbvalue));
  }, [filterCountNumber, dbvalue]);

  // useEffect(() => {
  //   dbvalue ? setFilterCountNumber(currentPage) : setFilterCountNumber(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    if (dbvalue !== "") {
      setFilterCountNumber(1);
      dispatch(fetchCoupon(filterCountNumber,dbvalue));
      return
    }
    if (dbvalue === "" || dbvalue == null) {
      setFilterCountNumber(1);
      dispatch(fetchCoupon(filterCountNumber,dbvalue));
      return
    }
  }, [dbvalue]);

  const setfilterVal = (val) => {
    setFilter(val);
  };

  const setFilterCount = (count) => {
    setFilterCountNumber(count);
  };

  return (
    <>
      <Layout>
        <Box className={classes.component}>
          <Box>
            {getCoupon?.loading && (
              <Grid
                container
                justifyContent="center"
                style={{
                  height: "100vh",
                  width: "100%",
                  position: "absolute",
                }}
                alignItems="center"
              >
                <ReactLoading type="spinningBubbles" color="blue" />
              </Grid>
            )}
            <Table
              name="Coupons"
              tableTitle="Coupons"
              tableHead={tableHead}
              type="couponList"
              tableBody={listCoupon}
              filtercount={listCoupon?.data || []}
              isLoadingData={getCoupon?.loading || false}
              TableFilterCoun={FilterCouponcount}
              items="false"
              setfilterVal={setfilterVal}
              setFilterCount={setFilterCount}
              filtertransactions={getCoupon}
              paginate={true}
              dbvalue={dbvalue}
              setSearchData={setSearchData}
              initCurrentPage={state?.currentPage || 0}
              filterCountNumber={filterCountNumber}
            />
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default CouponList;
