import React, { useCallback, useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import ReactLoading from "react-loading";
import Table from "../components/table/Table";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchWalkInScreens } from "../redux/user/action";
import debounce from "lodash.debounce";



const WalkInScreenList = () => {

    const useStyles = makeStyles({
        component: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
        },
        card: {
            display: "flex",
            justifyContent: "space-between",
        },
        progress: {
            display: "flex",
            justifyContent: "space-between",
            padding: "35px 0px",
        },
    });

    const tableHead = [
        { id: 1, title: "S.No." },
        { id: 2, title: "Title" },
        { id: 3, title: "Subtitle" },
        { id: 4, title: "Description" },
        { id: 5, title: "Screen Order" },
        { id: 6, title: "Action" },
    ];
    const [data, setSearchData] = useState(null);
    const [dbvalue, setDbvalue] = useState(null);

    const [filter, setFilter] = useState(null)
    const [filterCountNumber, setFilterCountNumber] = useState("1")
    const classes = useStyles();
    const dispatch = useDispatch();

    const getWalkInScreen = useSelector(
        ({ fetchWalkInScreensReducer }) => fetchWalkInScreensReducer
    );

    const debounsedSaved = useCallback(
        debounce((data) => setDbvalue(data), 1000),
        []
    );
    debounsedSaved(data)

    useEffect(() => {
        dispatch(fetchWalkInScreens(filter, filterCountNumber, dbvalue))
    }, [filter, filterCountNumber, dbvalue])

    const setfilterVal = (val) => {
        setFilter(val)
    }
    const setFilterCount = (count) => {
        setFilterCountNumber(count)
    }

    const listWalkInScreen = getWalkInScreen?.items;
    return (
        <>
            <Layout>
                <Box className={classes.component}>
                    <Box>
                        {getWalkInScreen?.loading && (
                            <Grid
                                container
                                justifyContent="center"
                                style={{
                                    height: "100vh",
                                    width: "100%",
                                    position: "absolute",
                                }}
                                alignItems="center"
                            >
                                <ReactLoading type="spinningBubbles" color="blue" />
                            </Grid>

                        )}
                        <Table
                            name="Walk-in Screens"
                            tableTitle="Walk-in Screen List"
                            tableHead={tableHead}
                            tableBody={listWalkInScreen}
                            paginate={true}
                            isLoadingData={getWalkInScreen?.loading || false}
                            type="walkinList"
                            TableFilterCoun={0}
                            items="false"
                            setfilterVal={setfilterVal}
                            setFilterCount={setFilterCount}
                            filtertransactions={getWalkInScreen}
                            dbvalue={dbvalue || null}
                            setSearchData={setSearchData}
                        />
                    </Box>
                </Box>
            </Layout>
        </>
    );
};

export default WalkInScreenList;
