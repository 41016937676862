/* eslint-disable no-unused-expressions */
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, resetPasswordSetinitialStateFalse } from "../../redux/auth/action";
import CustomButton from "../custom/Button";
import InputField from "../custom/Input";
import { useNavigate } from "react-router-dom";
import localkings from "../../assets/images/icon.svg";
import { Grid } from "@mui/material";

export default function ResetPasswordForm() {
    const useStyles = makeStyles({
        formGroup: {
            display: "flex",
            flexDirection: "column",
        },
        formControl: {
            border: "1px solid #E6EAF0 !important",
            height: "48px",
            borderRadius: "5px",
            padding: "0rem 1rem",
        },

        label: {
            height: "100%",
            maxWidth: "100%",
            marginTop: "1em",
            fontWeight: 500,
            fontSize: "15px"
        },
        button: {
            background: "rgb(0, 58, 210)",
            width: "100%",
            height: "40px",
            border: "none",
            borderRadius: "5px",
            color: "white",
            marginTop: "10px",
            fontWeight: 600
        },
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(resetPasswordSetinitialStateFalse());
    }, [])

    const resetPasswordReducer = useSelector(state => state.resetPasswordReducer);
    var err =resetPasswordReducer.error
    // const [err,setErr] = useState("")

    if (resetPasswordReducer.resetPassword === true) {
        window.localStorage.clear();
        navigate('/login');
    }

    const [getInputField, setInputField] = useState({
        password: "",
        passwordConfirm: "",
    });

    const [getOTP, setOtp] = useState("");
    const [getEmail, setEmail] = useState("");
    const [mismatch, setMismatch] = useState(false);
    const [error, setError] = useState(resetPasswordReducer?.error);
    const currentUser = useSelector(({ loginReducer }) => loginReducer);

    const InputsObj = [
        {
            id: 1,
            label: "New Password",
            palceHolder: "Enter New Password",
            type: "password",
            name: "password"
        },
        {   
            id: 2,  
            label: "Confirm New Password",
            type: "password",
            palceHolder: "Enter Confirm Password",
            name: "passwordConfirm"
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...getInputField, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = { ...getInputField, otp: getOTP, email: getEmail }
        if (getInputField.password !== getInputField.passwordConfirm) {
            setMismatch(true)
        }else{
            setMismatch(false)
        }
        dispatch(resetPassword(data));
    };

    useEffect(() => {
        setOtp(localStorage.getItem("otp"));
        setEmail(localStorage.getItem("email"));
    }, [])

    return (
        <>
            {currentUser.loading ? (
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                    }}
                    alignItems="center"
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h2 style={{ color: 'blue', marginRight: '20px' }}>Please Wait...</h2>
                        <CircularProgress style={{ color: 'blue' }} />
                    </div>
                </Grid>
            ) : (
                <>
                    <div id="main" className="formbox login_form">
                        <div className="box">
                            <div className="font-25 font-we text-center mb-4">
                                <img src={localkings} alt="" />
                                <span className="d-block mt-2 font-weight-700">LocalKings</span>
                                <span className="d-block mt-2 font-weight-700">Reset Password</span>
                            </div>

                            <form onSubmit={handleSubmit}>

                                {InputsObj.map((val) => {
                                    const cl = `col-lg-${val.type === "file" ? 12 : 12
                                        } col-sm-12`;
                                    return (
                                        <div className={`${cl} mt-4`} keys={val.id}>
                                            <InputField
                                                id={val.id}
                                                label={val.label}
                                                type={val.type}
                                                placeholder={val.palceHolder}
                                                option={val.option}
                                                handleChange={handleChange}
                                                name={val.name}
                                                classStyle={classes}
                                            />
                                        </div>
                                    );
                                })}


                                {

                                    resetPasswordReducer.error == "You are using your old password" ?
                                        <div className="col-md-12">
                                            <span className="font-15 font-weight-500 d-block mt-2" style={{ color: " #AF2828", }}>
                                                {err}
                                            </span>
                                        </div>
                                        : ""

                                }

                                {
                                    mismatch === true && getInputField.password !== getInputField.passwordConfirm ?
                                        <div className="col-md-12">
                                            <span className="font-15 font-weight-500 d-block mt-2" style={{ color: " #AF2828", }}>
                                                Password And Confirm Password not match
                                            </span>
                                        </div> : null
                                }


                                <div style={{ display: "flex", marginTop: "20px" }}>
                                    <CustomButton type="submit" className={classes.button}  >
                                        Save
                                    </CustomButton>
                                </div>
                            </form>
                        </div>
                    </div>

                </>
            )
            }
        </>
    );
}
